import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import appRoutes from '../configs/AppRoutes';

const TopBackButton = () => {

    const navigate = useNavigate();
    const { loading, pageData } = useSelector((state) => state.common);

    return (
        <div className='d-flex position-absolute ps-3 pt-3 top-0 z-1 top-back'>
            <Button onClick={() => navigate(appRoutes.viewPageDetailPageRoute+'/'+pageData.id)} variant='' className='align-items-center btn btn-outline-light d-inline-flex p-0 pe-2 text-white'>
                <img src={process.env.PUBLIC_URL + '/assets/images/chevron-left.png'} /><span>Back</span>
            </Button>
        </div>
    );
};

export default TopBackButton;
