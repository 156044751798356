import React from 'react';
import Button from 'react-bootstrap/Button';
import { useNavigate, useParams } from 'react-router-dom';
import appRoutes from '../../configs/AppRoutes';

const Home = () => {
    const navigate = useNavigate();
    const params = useParams();

//function Home() {
    return (
        <div className='siteWidth gap-top-negative'>
            <div className="welcome-bg">
                <div className='d-flex flex-column justify-content-center outer'>
                    <div className="logo-circle text-center">
                        <img src={process.env.PUBLIC_URL + '/assets/images/welcome-logo.png'}></img>
                    </div>
                    <div className='gap-3 d-flex flex-column'>
                        <Button className='w-100 btn-lg btn-light' variant="light" onClick={() => navigate(appRoutes.loginPageRoute+'/'+params.id)}
                        >Login</Button>
                        <Button className='w-100 btn-lg btn-dark' onClick={() => navigate(appRoutes.registerPageRoute+'/'+params.id)}
                        >Register</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
