import React from "react";



const TermsAndConditions = () => {
    const terms = [
        {
            title: "Image Upload Limitations",
            description: `Each user is allowed to add a maximum of 10 images to the event. This limitation is in place to ensure that the event remains organized and manageable, preventing an overwhelming number of images from being submitted. By capping the number of images per user, we encourage thoughtful contributions and maintain a cohesive experience for all participants. Users are encouraged to select their best images to represent their contributions effectively within this limit.`,
        },
        {
            title: "Group Admin Restrictions",
            description: `After creating a group, the group admin is unable to join the group unless they first leave it. This policy ensures that the admin maintains a clear distinction between their administrative role and group membership. By preventing the admin from joining, it allows for better management and oversight of the group dynamics. If the admin wishes to participate as a member, they will need to remove the group entirely, which may be a significant consideration before proceeding with group creation.`,
        },
        {
            title: "Automatic Group Deletion",
            description: `After logging in, if a user has created a group but has not added any members within a certain period (e.g., 5 hours), the group will be automatically deleted. After this, the user will be able to create a new group or join other groups.`,
        },
    ];

    return (
        <div className="p-4">
            <h1 className="text-2xl font-bold mb-4">Terms and Conditions</h1>

            {terms.map((term, index) => (
                <div key={index} className="mb-6">
                    <h2 className="text-xl font-semibold">{term.title}</h2>
                    <p className="mb-2">{term.description}</p>
                  
                </div>
            ))}
        </div>
    );
};

export default TermsAndConditions;
