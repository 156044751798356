import STRINGS from '../../../common/strings/strings';

export function validateUpdateBucket(bucketData, fields = [], fieldName) {
    const { title } = bucketData;
    let errors = {};
    let formValidCheck = true;
    // Validate bucket title
    if (title === '' && (fieldName === 'title' || fieldName === '' || fieldName === undefined)) {
        errors.title =  STRINGS.product.titleReq; // Assuming you have a corresponding string for title required
        formValidCheck = false;
    }

    // Ensure fields is an array
    if (Array.isArray(fields)) {
        // Validate item titles
        fields.forEach((field, index) => {
            if (field.title === '' && (fieldName === `itemTitle${index}` || fieldName === '' || fieldName === undefined)) {
                errors[`itemTitle${index}`] = STRINGS.product.itemTypeReq; // Assuming you have a corresponding string for item title required
                formValidCheck = false;
            }
        });
    } 

    return { formValidCheck, errors };
}
