import React, { useState, useEffect } from 'react';
import { sendRequest } from "../../../apis/APIs";
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Dropdown } from 'react-bootstrap';
import moment from "moment";
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import { handleApiResponse } from "../../../apis/apiUtils";
import { useSelector, useDispatch } from 'react-redux';
import TopBackButton from '../../../common/TopBackButton';
import appRoutes from '../../../configs/AppRoutes';


const List = () => {
  const params = useParams();
  const [bucketList, setBucketList] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, user, pageData } = useSelector((state) => state.common);

  useEffect(() => {
    getBucketList();
  }, []);

  const getBucketList = async () => {
    try {
      let reqUrl = `/user/page/` + pageData.id + `/bucket-list`;
      const res = await sendRequest(reqUrl, "GET");
      if (res.data.type === "success") {
        setBucketList(res.data.data);

      }
    } catch (error) {
      console.log('Something went wrong');
    }
  };

  const deleteBucketList = async (listId) => {
    try {

      let reqUrl = `/user/bucket-list/delete/` + listId;
      dispatch(callCommonAction({ loading: true }));
      const res = await sendRequest(reqUrl, "DELETE");
      dispatch(callCommonAction({ loading: false }));
      handleApiResponse(res);
      if (res.data.type === "success") {
        getBucketList();
      }
    } catch (error) {
      console.log('Something went wrong');
    }
  };

  return (
    <div>
      <div className='px-3'>
        <div className="title-header">
          <div>
            <h5 className="mb-0"><i class="fa-solid fa-list-check"></i> Bucket List</h5>
          </div>

        </div>
        <div className='listing'>
          {bucketList && bucketList.length > 0 ? (
            bucketList.map((item, index) => (
              <div className='list-item' key={index}>
                <div className='d-flex align-items-center'>
                  <div className='w-100'>
                    <h2>{item.title}</h2>
                    <p className='m-0'><a href="#" className='link'><i className="fa-solid fa-calendar me-1"></i> ({moment(item.created_at).format("D MMM YYYY")})</a></p>
                  </div>
                </div>
                <div>
                  {user.id === item.created_by && (
                    <Dropdown className="custom-dropdown">
                      <Dropdown.Toggle as="button" className="btn">
                        <i className="fa-solid fa-ellipsis-vertical"></i>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => navigate(appRoutes.bucketListUpdatePageRoute + '/' + item.id)}>
                          <i className="fa-solid fa-pencil me-1"></i> Edit
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => deleteBucketList(item.id)}>
                          <i className="fa-solid fa-times me-1"></i> Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </div>

              </div>
            ))
          ) : (
            <p>No Data Available</p>
          )}
        </div>
      </div>
      <div className="mt-4">
        <p onClick={() => navigate(pageData ? appRoutes.viewPageDetailPageRoute + '/' + pageData.id : appRoutes.selectPageTypePageRoute)} className='text-center small text-primary text-decoration-underline pointer'><i className="fa-solid fa-chevron-left small"></i> Back</p>
      </div>
    </div>
  );
};

export default List;
