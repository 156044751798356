import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Button, FloatingLabel, Form } from 'react-bootstrap';
import { validateEventFormData } from "./Validation";
import { sendRequest } from "../../../apis/APIs";
import axios from 'axios';
import Autosuggest from 'react-autosuggest';
import { useDropzone } from 'react-dropzone';
import appRoutes from '../../../configs/AppRoutes';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import { handleApiResponse } from '../../../apis/apiUtils';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const Create = () => {

    // Define the API endpoint
    const params = useParams();
    const dispatch = useDispatch();
    const { loading, pageData, user } = useSelector((state) => state.common);
    const [errorsInfo, setErrorsInfo] = useState({});
    const navigate = useNavigate();
    const [suggestions, setSuggestions] = useState([]);
    const defaultPosition = [parseFloat(user?.loc_latitude) || parseFloat(user?.loc_langitude) || 40.712776, -74.005974]; // Default location
    const [markerPosition, setMarkerPosition] = useState(defaultPosition);
    const [files, setFiles] = useState([]);
    const [savedFiles, setSavedFiles] = useState([]);
    const [joindMembers, setJoindMembers] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [eventDetail, setEventDetail] = useState({
        name: '',
        description: '',
        date_time: '',
        location: '',
        latitude: '',
        longitude: '',
        members_invited: ''
    });
    const [location, setLocation] = useState('');
    useEffect(() => {
        if (params.id) {
            getEventDetail();
        }
        getPageData();
        getJoindMembers();
    }, []);

    const getEventDetail = async () => {
        try {
            let reqUrl = `/user/page/` + pageData.id + '/event/' + params.id;
            const res = await sendRequest(reqUrl, "GET");
            if (res.data.type === "success") {
                setEventDetail({
                    ...eventDetail,
                    name: res.data.data.name,
                    description: res.data.data.description,
                    date_time: res.data.data.date_time,
                    location: res.data.data.location,
                    lattitude: res.data.data.lattitude,
                    longitude: res.data.data.longitude,
                    members_invited: res.data.data.members_invited,
                });
                let selectedOptions = res.data.data.members_invited.split(',');
                setSelectedOptions(selectedOptions);
                setSavedFiles(res.data.data.galleries);

            }
        } catch (error) {
            toast.error('Something went wrong');
        }
    };

    const handleChangeOptions = (event) => {
        // Get the selected options as an array of values
        const options = Array.from(event.target.selectedOptions, option => option.value);
        // Convert array to comma-separated string and store in state
        setSelectedOptions(options);
        let commaSeparatedOptions = options.join(',');
        setEventDetail({ ...eventDetail, members_invited: commaSeparatedOptions });
    };
    const selectedArray = selectedOptions;
    const getPageData = async () => {
        // e.preventDefault();
        //if (validateOtp()) {
        const res = await sendRequest(`/user/get-page-data`, "GET",);
        if (res.data.type === "success") {

            if (res.data.page_data) {
                dispatch(callCommonAction({ pageData: res.data.page_data }));
                localStorage.setItem('pageData', JSON.stringify(res.data.page_data));
                //navigate(appRoutes.viewPageDetailPageRoute + '/' + res.data.page_data.id);
            }


        } else {
            //toast.error(res.data.message || 'Otp not valid');
        }
        //}
    };
    const getJoindMembers = async () => {
        try {
            let reqUrl = `/user/joind-members`;
            const res = await sendRequest(reqUrl, "POST", { member_ids: pageData.members_joined });
            if (res.data.type === "success") {
                setJoindMembers(res.data.data);
            }
        } catch (error) {
            //toast.error('Something went wrong');
        }
    };


    console.log(joindMembers, "joinedMembers");

    // Handle file addition
    const handleDrop = (acceptedFiles) => {
        setFiles((prevFiles) => [
            ...prevFiles,
            ...acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            ),
        ]);
        //({ ...eventDetail, files: acceptedFiles });
    };

    // Handle file removal
    const handleRemove = (file) => {
        setFiles((prevFiles) =>
            prevFiles.filter((f) => f !== file)
        );
        URL.revokeObjectURL(file.preview); // Clean up the URL object
    };

    const deleteGallery = async (id) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "Do you really want to delete this gallery? This action cannot be undone.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
        });

        if (result.isConfirmed) {
            try {
                // Start loading
                dispatch(callCommonAction({ loading: true }));

                // Prepare API URL
                const reqUrl = `/user/event/gallery/delete/${id}`;

                // Perform API call
                const res = await sendRequest(reqUrl, "DELETE");

                // Stop loading
                dispatch(callCommonAction({ loading: false }));

                // Handle API response
                handleApiResponse(res);

                // If successful, navigate to another page
                if (res.data.type === "success") {
                    getEventDetail();
                }
            } catch (error) {
                // Handle errors
                console.error('Something went wrong', error);
                dispatch(callCommonAction({ loading: false }));
                //toast.error('Something went wrong');
            }
        } else {
            // If user cancels, do nothing
            console.log("Deletion canceled.");
        }
    };


    // Dropzone configuration
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: handleDrop,
        multiple: true,
        accept: 'image/*',
    });



    const handleChange = (e) => {
        // Create a copy of the eventDetail state
        const updatedEventDetail = { ...eventDetail };
    
        // Handle year validation if the changed input is date_time
        if (e.target.name === 'date_time') {
            const value = e.target.value;
            const year = value.split('-')[0]; // Extract the year from the value
    
            if (year.length > 4) {
                // Show an error if the year exceeds 4 digits
                setErrorsInfo((prevErrors) => ({
                    ...prevErrors,
                    date_time: "Year must be 4 digits."
                }));
                return; // Prevent updating the state if the year is invalid
            } else {
                // Clear the error if the year is valid
                setErrorsInfo((prevErrors) => ({
                    ...prevErrors,
                    date_time: ""
                }));
            }
        }
    
        // Update the eventDetail with the new value
        updatedEventDetail[e.target.name] = e.target.value;
    
        // Update the state with the new eventDetail
        setEventDetail(updatedEventDetail);
    
        // Call the function to check if the form is valid
        checkFormIsValid(e.target.name);
    };
    
    /** Validation implemented here */
    const checkFormIsValid = async () => {
        const res = validateEventFormData(eventDetail);
        setErrorsInfo(res.errors);
        //console.log('setErrorsInfo', errorsInfo, await checkFormIsValid(""))
        return res.formVaildCheck;
    };

    const saveEventDetail = async (e) => {
        e.preventDefault();
        if (await checkFormIsValid("")) {
            try {
                const formData = new FormData();
                Object.keys(eventDetail).forEach((key) => {
                    formData.append(key, eventDetail[key]);
                });
                //const filesArray = Array.from(eventDetail.files);
                if (files.length > 0) {
                    files.forEach(file => {
                        formData.append('files[]', file);
                    });
                }

                dispatch(callCommonAction({ loading: true }));
                let reqUrl = params.id ? `/user/page/` + pageData.id + `/event/update/` + params.id : `/user/page/` + pageData.id + `/event/create`;
                const res = await sendRequest(reqUrl, "POST", formData);
                dispatch(callCommonAction({ loading: false }));
                handleApiResponse(res);
                if (res.data.type === "success") {
                    navigate(appRoutes.viewPageDetailPageRoute + '/' + pageData.id);
                }
            } catch (error) {
                //toast.error('Something went wrong');
                dispatch(callCommonAction({ loading: false }));
            }
        }
    };



    let cancelToken; // Declare a cancel token outside of the function 
    const handleInputChange = async (e) => {
        const value = e.target.value;
        setLocation(value);
        setEventDetail({ ...eventDetail, location: '' });
        if (value) {
            // Cancel the previous request if a new one is initiated
            if (cancelToken) {
                cancelToken.cancel("Previous request canceled due to new input.");
            }

            // Create a new CancelToken
            cancelToken = axios.CancelToken.source();

            try {
                const response = await axios.get(`https://nominatim.openstreetmap.org/search?format=json&q=${value}&addressdetails=1`, {
                    cancelToken: cancelToken.token, // Attach the cancel token to the request
                });

                console.log(response, "resposeeeeeee");
                setSuggestions(response.data); // Update suggestions
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.log("Request canceled:", error.message);
                } else {
                    console.error("Error fetching suggestions:", error);
                }
                setSuggestions([]); // Clear suggestions in case of error
            }
        } else {
            setSuggestions([]); // Clear suggestions if input is empty
        }
    };


    const handleSuggestionClick = (suggestion) => {
        const { lat, lon, display_name } = suggestion;
        // Update marker position and location in the search box
        setMarkerPosition([lat, lon]);
        setLocation(display_name);
        setEventDetail({ ...eventDetail, location: display_name, lattitude: lat, longitude: lon });
        setSuggestions([]); // Clear suggestions
    };


    return (
        <div className='outer bg-lightBrown' >
            <div className="form-container">
                <form className="create-page-form" encType="multipart/form-data">
                    <div className="icon-picture">
                        <i className="fa-solid fa-user-group"></i>
                    </div>
                    <div className="text-center mb-11">
                        <h1 className="title mb-4">{params.id ? 'Update' : 'Create'} Event</h1>
                    </div>

                    <div className="mb-3">
                        <FloatingLabel controlId="floatingDate" label="Event Name">
                            <Form.Control
                                className="input-field form-control"
                                type="text"
                                placeholder="Enter group name"
                                name="name"
                                value={eventDetail.name}
                                onChange={handleChange}
                            />
                        </FloatingLabel>
                        <div className="error-message">{errorsInfo.name}</div>
                    </div>


                    <div className="mb-3">
                        <FloatingLabel controlId="floatingDatetime" label="Event Date & Time">
                            <Form.Control
                                className="input-field form-control"
                                type="datetime-local"
                                name="date_time"
                                placeholder="Select date and time"
                                value={eventDetail.date_time}
                                onChange={handleChange}
                            />
                        </FloatingLabel>
                        <div className="error-message">{errorsInfo.date_time}</div>
                    </div>

                    <div>
                        <FloatingLabel controlId="floatingDate" label="Type an address">
                            <Form.Control
                                className="input-field form-control"
                                type="text"
                                placeholder="Type an address"
                                value={location}
                                onChange={handleInputChange}
                            />
                        </FloatingLabel>
                        <div className="error-message">{errorsInfo.location}</div>
                    </div>
                    <div>
                        {suggestions.length > 0 && (
                            <ul className="bg-white border list-unstyled p-3 rounded-3 suggestions-list">
                                {suggestions.map((suggestion) => (
                                    <li className='bg-snuff mb-2 small' key={suggestion.place_id} onClick={() => handleSuggestionClick(suggestion)}>
                                        {suggestion.display_name}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    {/* <div className="mb-3">
                        <Form.Select className="input-field form-control" multiple aria-label="Default select example" onChange={handleChangeOptions} value={selectedArray} name="members_invited">
                            <option>Open this select menu</option>
                            {
                                joindMembers.length > 0 ? (
                                    joindMembers.filter(users => users.id !== user?._id).map((users) => (
                                        <option key={users.id} value={users.id}>
                                            {users.name}
                                        </option>
                                    ))
                                ) : (
                                    <option value="">No options available</option>
                                )
                            }
                        </Form.Select>
                        <div className="error-message">{errorsInfo.members_invited}</div>
                    </div> */}

                    <div className="mt-3 mb-3">
                        <FloatingLabel controlId="floatingDate" label="Event Description">
                            <Form.Control
                                as="textarea"
                                className="input-field form-control"
                                placeholder="Enter event description"
                                name="description"
                                value={eventDetail.description}
                                onChange={handleChange}
                                style={{ height: '100px' }} // Adjust the height as needed
                            />
                        </FloatingLabel>
                        <div className="error-message">{errorsInfo.description}</div>
                    </div>

                    <div className="image-upload">
                        {/* Dropzone area */}
                        <div {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps()} />
                            <p>Drag & drop some files here, or click to select files</p>
                        </div>

                        {/* Image previews */}
                        <div className="image-previews">
                            {savedFiles && savedFiles.length > 0 && savedFiles.map((file, index) => (
                                <div key={index} className="image-preview">
                                    <img src={file.file ? process.env.REACT_APP_ASSET_ENDPOINT + '/' + file.file : ''} alt={file.file} />
                                    <button type="button" className="remove-button" onClick={() => deleteGallery(file.id)}>×</button>
                                </div>
                            ))}
                            {files.map((file) => (
                                <div key={file.name} className="image-preview">
                                    <img src={file.preview} alt={file.name} />
                                    <button className="remove-button" onClick={(event) => {
                                        event.preventDefault(); // Prevent default behavior
                                        handleRemove(file); // Call the remove function
                                    }}>×</button>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="text-center mb-4">
                        <Button className='w-100 btn-lg btn-primary shadow' disabled={loading} onClick={saveEventDetail}>
                            {loading ? (
                                <span className="indicator-progress" style={{ display: 'block' }}>
                                    Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            ) : (
                                <span className="indicator-label">{params.id ? 'Update' : 'Create'}</span>
                            )}
                        </Button>
                    </div>
                    <div className="">
                        <p onClick={() => navigate(pageData ? appRoutes.viewPageDetailPageRoute + '/' + pageData.id : appRoutes.selectPageTypePageRoute)} className='text-center small text-primary text-decoration-underline pointer'>
                            <i className="fa-solid fa-chevron-left small"></i> Back
                        </p>
                    </div>
                </form>
            </div>
        </div >
    );
};

export default Create;
