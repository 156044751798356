import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { callCommonAction } from '../../redux/Common/CommonReducer';
import { validateRegisterFormData } from "./Validation";
import appRoutes from '../../configs/AppRoutes';
import { sendRequest } from "../../apis/APIs";
import { Button, FloatingLabel, Form } from 'react-bootstrap';
import { handleApiResponse } from "../../apis/apiUtils";
import { toast } from 'react-toastify';

const Register = () => {
    const params = useParams();
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);
    const [userDetail, setUserDetail] = useState({ role: 2, name: "", email: "", password: "", password_confirmation: "", nfc_tag: params.id });
    const [errorsInfo, setErrorsInfo] = useState({});
    const { loading } = useSelector((state) => state.common);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [showTermsError, setShowTermsError] = useState(false);

    /** OnChange Update Input Values */
    const handleChange = (e) => {
        userDetail[e.target.name] = e.target.value;
        setUserDetail(userDetail);
        checkFormIsValid(e.target.name);
    };

    /** Checkbox Change Handler */
    const handleTermsChange = (e) => {
        setIsTermsAccepted(e.target.checked);
        if (e.target.checked) {
            setShowTermsError(false); // Hide error if terms are accepted
        }
    };
    /** Validation implemented here */
    const checkFormIsValid = async (fieldName) => {
        const res = validateRegisterFormData(userDetail, fieldName);
        if (fieldName) {
            fieldName = fieldName.trim();
            let err = res.errors[fieldName];
            if (typeof err != "undefined") {
                errorsInfo[fieldName] = err;
            } else {
                errorsInfo[fieldName] = "";
            }
            setErrorsInfo({ ...errorsInfo });
        } else {
            setErrorsInfo({ ...res.errors });
        }

        return res.formVaildCheck;
    };

    /** Form Submit Functionality Here */
    const registerUser = async () => {
        if (!isTermsAccepted) {
            setShowTermsError(true); // Show error when trying to submit
            return; // Early return if terms are not accepted
        }
        if (await checkFormIsValid("")) {
            dispatch(callCommonAction({ loading: true }));
            const res = await sendRequest(`/user/register`, "POST", userDetail);
            dispatch(callCommonAction({ loading: false }));
            if (res?.data?.message?.email && res.data.message.email.length > 0) {
                toast.error(res.data.message.email[0]);
            } else {
                handleApiResponse(res);

            }
            if (res.data.type === "success") {
                navigate(appRoutes.loginPageRoute + '/' + params.id);
            } else {
                toast.error(res.data.message || 'Failed to register user');
            }
        }

    };

    // Function to handle navigation
    const handleNavigateToTerms = () => {
        console.log(appRoutes.termsConditionsPageRoute,"appRoutes.termsConditionsPageRoute")
        navigate(appRoutes.termsConditionsPageRoute);
    };


    return (
        <div>
            <div className='siteWidth gap-top-negative'>
                <div className='d-flex flex-column justify-content-center outer bg-light position-relative'>
                    <img className='top-right position-absolute' src={process.env.PUBLIC_URL + '/assets/images/top-right-shape.png'}></img>
                    <div className="form-container">
                        <form
                            className="register-form">
                            <div className="text-center mb-11">
                                <h1 className="title mb-4">Create Account</h1>
                                <p className='mb-5'>Create an account so you can explore all the existing jobs</p>
                            </div>
                            <div className="mb-3">
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Name"
                                >
                                    <Form.Control className="input-field form-control" type="text"
                                        placeholder="Enter name"
                                        name="name"
                                        onChange={handleChange}
                                        value={userDetail.name} />
                                </FloatingLabel>
                                <div className="error-message">{errorsInfo.name}</div>
                            </div>
                            <div className="mb-3">
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Email"
                                >
                                    <Form.Control className="input-field form-control" type="email"
                                        placeholder="Enter Email"
                                        name="email"
                                        onChange={handleChange}
                                        value={userDetail.email} />
                                </FloatingLabel>
                                <div className="error-message">{errorsInfo.email}</div>
                            </div>
                            <div className="mb-3">
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Password"
                                >
                                    <Form.Control className="input-field form-control"
                                        type="password"
                                        placeholder="Password"
                                        name="password"
                                        value={userDetail.password}
                                        onChange={handleChange}
                                        autoComplete="off" />
                                </FloatingLabel>
                                <div className="error-message">{errorsInfo.password}</div>
                            </div>
                            <div className="mb-3">
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Confirm Password"
                                >
                                    <Form.Control className="input-field form-control"
                                        type="password"
                                        placeholder="Confirm Password"
                                        name="password_confirmation"
                                        value={userDetail.password_confirmation}
                                        onChange={handleChange}
                                        autoComplete="off" />
                                </FloatingLabel>
                                <div className="error-message">{errorsInfo.password_confirmation}</div>
                            </div>
                            <div className="mb-3 form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="termsCheckbox"
                                    checked={isTermsAccepted}
                                    onChange={handleTermsChange}
                                />
                                <label className="form-check-label" htmlFor="termsCheckbox">
                                    I accept the <a onClick={handleNavigateToTerms} style={{textDecoration:'underline',cursor:"pointer"}}>Terms and Conditions</a>
                                </label>
                                {showTermsError && <div className="error-message">You must accept the Terms&Conditions.</div>} {/* Conditionally render error */}
                            </div>
                            <div className="text-center mb-4">
                                <Form.Control className="input-field form-control"
                                    type="hidden"
                                    name="nfc_tag"
                                    value={params.id}
                                />
                                <Button onClick={registerUser} className='w-100 btn-lg btn-primary shadow'
                                    disabled={loading ? true : false}
                                >{
                                        loading
                                            ?
                                            <span className="indicator-progress" style={{ display: 'block' }}>
                                                Please wait...
                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                            :
                                            <span className="indicator-label">Register</span>
                                    }
                                </Button>
                            </div>
                            <div className="">
                                <p onClick={() => navigate(appRoutes.loginPageRoute + "/" + params.id)} className='text-center small text-primary text-decoration-underline pointer'>Login</p>
                            </div>
                        </form>
                    </div>
                    <img className='bottom-left position-absolute' src={process.env.PUBLIC_URL + '/assets/images/bottom-left-shape.png'}></img>
                </div>
            </div>
        </div>
    );
};

export default Register;
