import React, { Suspense, useEffect } from 'react';
import { BrowserRouter } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { callCommonAction } from './redux/Common/CommonReducer';
import { sendRequest } from './apis/APIs';
import UserRoute from './routes/UserRoute';
import 'bootstrap/dist/css/bootstrap.min.css';
import appRoutes from './configs/AppRoutes';
import './css/custom.css';




function App() {
  const dispatch = useDispatch();
  const { isLoggedIn, user } = useSelector((state) => state.common);


  // useEffect(() => {
  //   if (isLoggedIn){
  //     console.log('user', user)
  //   }else{
  //     //window.location.href = appRoutes.loginPageRoute;
  //     //window.location(appRoutes.loginPageRoute)
  //   }
  //     console.log('isLoggedIn', isLoggedIn)
      
  // });

 

  // //const navigate = useNavigate();
  // /** Only Load Time Set State From LocalStorage */
  useEffect(() => {
    
    if (localStorage.token !== undefined && localStorage.token !== null) {
      dispatch(callCommonAction({
        isLoggedIn: true,
        token: localStorage.token,
        user: JSON.parse(localStorage.user),
        pageData: localStorage.pageData ? JSON.parse(localStorage.pageData) : null,
      }));
    }
    //without user logged in we also need raise Notification Bell
    //dispatch(callCommonAction({ raiseNotificationBell: raiseNotificationBell }));
  }, [isLoggedIn]);

  // useEffect(() => {
  //   if (localStorage.token !== undefined && localStorage.token !== null) {
  //     checkUserExist();
  //   }

  // }, []);
  
  // const checkUserExist = async () => {
  //   let userData = JSON.parse(localStorage.user)
  //   try {
  //     const res = await sendRequest(`/user/detail/${userData.id}`, 'GET');
  //     if (res.data.data === null) {
  //       localStorage.removeItem("token");
  //       localStorage.removeItem('user');
  //       localStorage.removeItem('isAdmin');
  //       localStorage.removeItem('subscription');
  //       dispatch(callCommonAction({ isLoggedIn: false, token: null, user: null, isAdmin: false }));
  //       isAdmin ? window.location('/admin/login') : window.location('/user/login');
  //     }
  //   } catch (error) {
  //     toast.error('Something went wrong');
  //   }
  // };

  return (
    
      <BrowserRouter>
        <ToastContainer autoClose={1500} />
        <Suspense>
          <UserRoute />
        </Suspense>
      </BrowserRouter>
    
  );
}

export default App;
