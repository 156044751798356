import React from 'react';
import appRoutes from './../configs/AppRoutes';
import { Routes, Route } from 'react-router-dom';
import Home from './../components/Home/Home';
import Register from './../components/Auth/Register';
import Login from './../components/Auth/Login/Login';
import Otp from './../components/Auth/Otp/Otp';
import Profile from '../components/User/Profile';
import VerifyEmail from './../components/Auth/VerifyEmail';
import ForgotPassword from '../components/Auth/ForgotPassword';
import SelectPage from './../components/Page/SelectType';
import CreateUpdateCouple from '../components/Page/CreateUpdateCouple';
import CreateUpdateFriend from '../components/Page/CreateUpdateFriend';
import JoinPage from '../components/Page/JoinPage';
import ViewPage from '../components/Page/View';
import Info from '../components/Page/Info';
import CreateEventPage from '../components/Page/Event/Create';
import ThankYou from './../components/Auth/ThankYou';
import ViewEventPage from '../components/Page/Event/View';
import BucketListPage from '../components/Page/BucketList/List';
import CreateBucketListPage from '../components/Page/BucketList/Create';
import UpdateBucketListPage from '../components/Page/BucketList/Update';
import UpcomingEvents from '../components/Page/UpcommingPlan/List';
import UpcommingEvntCreate from '../components/Page/UpcommingPlan/CreateUpdate';
import NotificationPage from '../components/Notification/Index';
import CalendarSynchronizPage from '../components/Page/Calendar/Synchroniz';
import EventList from '../components/Page/Event/EventList';
import UpdatePassword from '../components/User/UpdatePassword';
import Terms from '../components/Terms&Conditions/Terms&Conditions';



// Containers
 const DefaultLayout = React.lazy(() => import('./../layout/DefaultLayout'));
const AuthLayout = React.lazy(() => import('./../layout/AuthLayout'));
// const CmsLayout = React.lazy(() => import('./../layout/CmsLayout'));

const UserRoute = () => {
    return (
        <Routes>
            <Route exact path="/" element={<DefaultLayout />} >
                <Route exact index path={appRoutes.homePageRoute + "/:id?"} element={<Home />} />
                <Route exact path={appRoutes.registerPageRoute + "/:id?"} element={<Register />} />
                <Route exact path={appRoutes.loginPageRoute + "/:id?"} element={<Login />} />
                <Route exact path={appRoutes.thankYouPageRoute} element={<ThankYou />} />
                <Route exact path={appRoutes.otpPageRoute} element={<Otp />} />
                <Route exact path={appRoutes.verifyEmailPageRoute} element={<VerifyEmail />} />
                <Route exact path={appRoutes.forgotPasswordPageRoute+ "/:id?"} element={<ForgotPassword />} />
                <Route path={appRoutes.termsConditionsPageRoute} element={<Terms />} />

            </Route>

            <Route exact path="/user/" element={<AuthLayout />} >
                <Route path={appRoutes.profilePageRoute} element={<Profile />} />
                <Route path={appRoutes.updatePageRoute} element={<UpdatePassword />} />

            </Route>
            <Route exact path="/page/" element={<AuthLayout />} >
                <Route path={appRoutes.selectPageTypePageRoute} element={<SelectPage />} />
                <Route path={appRoutes.createCouplePageRoute} element={<CreateUpdateCouple />} />
                <Route path={appRoutes.createFriendPageRoute} element={<CreateUpdateFriend />} />
                <Route path={appRoutes.joinPageRoute} element={<JoinPage />} />
                <Route path={appRoutes.viewPageDetailPageRoute+ "/:id?"} element={<ViewPage />} />
                <Route path={appRoutes.updateCouplePageRoute+ "/:id?"} element={<CreateUpdateCouple />} />
                <Route path={appRoutes.updateFriendPageRoute+ "/:id?"} element={<CreateUpdateFriend />} />
                <Route path={appRoutes.infoPageRoute+ "/:id?"} element={<Info />} />
                <Route path={":page_id" + appRoutes.createEventPageRoute} element={<CreateEventPage />} />
                <Route path={appRoutes.updateEventPageRoute+ "/:id?"} element={<CreateEventPage />} />
                <Route path={appRoutes.viewEventPageRoute+ "/:id?"} element={<ViewEventPage />} />
                <Route path={appRoutes.bucketListPageRoute} element={<BucketListPage />} />
                <Route path={appRoutes.bucketListCreatePageRoute} element={<CreateBucketListPage />} />
                <Route path={appRoutes.bucketListUpdatePageRoute+ "/:id?"} element={<UpdateBucketListPage />} />
                <Route path={appRoutes.upcomingEventsPageRoute} element={<UpcomingEvents />} />
                <Route path={appRoutes.upcomingEventsCreatePageRoute} element={<UpcommingEvntCreate />} />
                <Route path={appRoutes.upcomingEventsUpdatePageRoute+ "/:id?"} element={<UpcommingEvntCreate />} />
                <Route path={appRoutes.notificationPageRoute} element={<NotificationPage />} />
                <Route path={appRoutes.calandersynchronisPageRoute} element={<CalendarSynchronizPage />} />
                <Route path={appRoutes.eventListRoute} element={<EventList />} />


            </Route>
        </Routes>
    );
};

export default UserRoute;
