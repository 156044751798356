import React, { useState, useEffect } from 'react';
import { sendRequest } from "../../apis/APIs";
import { useNavigate, useParams } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import moment from "moment";
import { callCommonAction } from '../../redux/Common/CommonReducer';
import { handleApiResponse } from "../../apis/apiUtils";
import { useSelector, useDispatch } from 'react-redux';
import appRoutes from '../../configs/AppRoutes';

const Index = () => {
    const [upcommingPlans, setUpcommingPlans] = useState({});
    const [notifications, setNotifications] = useState({});
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user, pageData } = useSelector((state) => state.common);

    useEffect(() => {
        getNotifications();
    }, []);

    const getNotifications = async () => {
        try {
            let reqUrl = `/user/notifications`;
            const res = await sendRequest(reqUrl, "GET");
            if (res.data.type === "success") {
                setNotifications(res.data.data);
            }
        } catch (error) {
            console.log('Something went wrong');
        }
    };


    return (
        <div>
            <div className='px-3'>
                <div className="title-header">
                    <div>
                        <h5 className="mb-0"><i className="fa-solid fa-bell"></i> Notifications</h5>
                    </div>

                </div>
                <div className='listing'>
                    {notifications && notifications.length > 0 ? (
                        notifications.map((item, index) => (
                            <div className='list-item' key={index}>
                                <div className='d-flex align-items-center'>
                                    <div className='w-100'>
                                        <h2>{item.title}</h2>
                                        <p className='m-0'>{item.description}</p>
                                    </div>
                                </div>
                                <div>
                                    <Dropdown className='custom-dropdown'>
                                        <Dropdown.Toggle as="button" className="btn">
                                            <i className="fa-solid fa-ellipsis-vertical"></i>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>

                                            <>
                                                <Dropdown.Item onClick={() => {
                                                    let route;

                                                    // Determine the route based on the item type
                                                    if (item.type === 'join') {
                                                        route = appRoutes.updateFriendPageRoute + '/' + item.action_id; // Replace with your actual route for 'join'
                                                    } else if (item.type === 'upcomming_plans') {
                                                        route = appRoutes.upcomingEventsPageRoute; // Existing route for 'upcoming'
                                                    } else {
                                                        route = appRoutes.bucketListPageRoute; // Optional: handle other types or default route
                                                    }

                                                    navigate(route); // Navigate to the determined route
                                                }}><i className="fa-solid fa-eye me-1"></i> View</Dropdown.Item>
                                            </>

                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>No Data Available</p>
                    )}
                </div>
            </div>
            <div className="mt-4">
                <p onClick={() => navigate(pageData ? appRoutes.viewPageDetailPageRoute + '/' + pageData.id : appRoutes.selectPageTypePageRoute)} className='text-center small text-primary text-decoration-underline pointer'><i className="fa-solid fa-chevron-left small"></i> Back</p>
            </div>
        </div>
    );
};

export default Index;
