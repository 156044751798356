import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { sendRequest } from "../../apis/APIs";
import { handleApiResponse } from "../../apis/apiUtils";
import { callCommonAction } from '../../redux/Common/CommonReducer';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { Button, FloatingLabel, Form } from 'react-bootstrap';
import appRoutes from '../../configs/AppRoutes';
import { validateUserFormData } from "./Validation";
import { toast } from 'react-toastify';

const Profile = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [userDetail, setUserDetail] = useState({});
    const [errorsInfo, setErrorsInfo] = useState({});
    const dispatch = useDispatch();
    const { loading, pageData } = useSelector((state) => state.common);
    const [fileName, setFileName] = useState(''); // State to store the file name
    const [filePreview, setFilePreview] = useState(null); // State to store the file preview URL

    useEffect(() => {
        getUserData();
    }, []);


    const getUserData = async () => {
        let reqUrl = `/user/profile`;
        const res = await sendRequest(reqUrl, "GET");
        if (res.data.type === "success") {
            setUserDetail(res.data.data);
            console.log(res.data.data, "res.data.data")
            setFilePreview(process.env.REACT_APP_ASSET_ENDPOINT + '/' + res.data.data.profile_picture);
        }
    };


    console.log(filePreview, "FILEpREEEE")
    const handleFileChange = (e) => {
        const file = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
        if (file) {
            setFileName(file.name); // Update the file name in state
            setFilePreview(URL.createObjectURL(file)); // Create a preview URL and update the state
            setUserDetail({
                ...userDetail, // Spread the existing properties of pageDetail
                profile_picture: file // Update the anniversary_date property
            });
        }
    };

    const handleChange = (e) => {
        userDetail[e.target.name] = e.target.value;
        setUserDetail(userDetail);
        checkFormIsValid(e.target.name);
    };

    /** Validation implemented here */
    const checkFormIsValid = async () => {
        const res = validateUserFormData(userDetail);
        setErrorsInfo(res.errors);
        return res.formVaildCheck;
    };

    const saveUserDetail = async (e) => {
        e.preventDefault();
        if (await checkFormIsValid("")) {
            try {
                const formData = new FormData();
                Object.keys(userDetail).forEach((key) => {
                    formData.append(key, userDetail[key]);
                });
                formData.append('profile_picture', filePreview);
                dispatch(callCommonAction({ loading: true }));
                let reqUrl = `/user/profile-update`;
                const res = await sendRequest(reqUrl, "POST", formData);
                dispatch(callCommonAction({ loading: false }));
                handleApiResponse(res);
                if (res.data.type === "success") {
                    localStorage.setItem('user', JSON.stringify(res.data.data));
                    dispatch(callCommonAction({ user: res.data.data }));
                    if (pageData) {
                        navigate(appRoutes.viewPageDetailPageRoute + '/' + pageData.id);
                    } else {
                        navigate(appRoutes.selectPageTypePageRoute);
                    }
                }
            } catch (error) {
                //toast.error('Something went wrong');
                dispatch(callCommonAction({ loading: false }));
            }
        }
    };

    return (
        <div className='outer bg-lightBrown'>
            <div className="form-container">
                <form
                    className="create-page-form"
                    encType="multipart/form-data"
                    onSubmit={saveUserDetail}
                >
                    <div className="icon-picture">
                        {filePreview ? (
                            <img src={filePreview} alt={fileName} className="" />
                        ) : (
                            <i className="fa-solid fa-user-group" />
                        )}
                    </div>


                    <div className="text-center mb-11">
                        <h1 className="title mb-4">Update Profile</h1>
                    </div>
                    {/* Image Upload Field */}
                    <div className="mb-3">
                        <Form.Group controlId="formFile" className="mb-3">
                            {/* <div className='uploadedFile my-3 text-center'>
                                {filePreview && <img src={filePreview} alt={fileName} className="image-preview" />}
                                {fileName && <p className="file-name m-0">{fileName}</p>}
                            </div> */}
                            <label className="custom-file-upload">
                                <input
                                    className="input-field form-control"
                                    type="file"
                                    name="profile_picture"
                                    accept="image/*"
                                    onChange={handleFileChange} // Attach the onChange handler
                                />
                                <i className="fa-solid fa-upload"></i> Upload Image
                            </label>
                        </Form.Group>
                        <div className="error-message">{errorsInfo.cover_picture}</div>
                    </div>

                    <div className="mb-3">
                        <FloatingLabel controlId="floatingDate" label="Enter  name">
                            <Form.Control
                                className="input-field form-control"
                                type="text"
                                placeholder="Enter  name"
                                name="name"
                                value={userDetail.name}
                                onChange={handleChange}
                            />
                        </FloatingLabel>
                        <div className="error-message">{errorsInfo.name}</div>
                    </div>

                    <div className="text-center mb-4">
                        <Button className='w-100 btn-lg btn-primary shadow' onClick={saveUserDetail}
                            disabled={loading ? true : false}>
                            {
                                loading
                                    ?
                                    <span className="indicator-progress" style={{ display: 'block' }}>
                                        Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                    :
                                    <span className="indicator-label">Update</span>
                            }
                        </Button>
                    </div>
                    <div className="text-center mb-4">
                        <Button className='w-100 btn-lg btn-primary shadow'
                            onClick={() => navigate(appRoutes.updatePageRoute)}   >

                            <span className="indicator-label">Update Password</span>
                        </Button>
                    </div>
                    <div className="">
                        <p onClick={() => navigate(pageData ? appRoutes.viewPageDetailPageRoute + '/' + pageData.id : appRoutes.selectPageTypePageRoute)} className='text-center small text-primary text-decoration-underline pointer'><i className="fa-solid fa-chevron-left small"></i> Back</p>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Profile;
