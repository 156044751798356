import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { sendRequest } from "../../apis/APIs";
import { handleApiResponse } from "../../apis/apiUtils";
import { callCommonAction } from '../../redux/Common/CommonReducer';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { Button, FloatingLabel, Form } from 'react-bootstrap';
import appRoutes from '../../configs/AppRoutes';
import { toast } from 'react-toastify';

const JoinPage = () => {
    const navigate = useNavigate();
    const [uniqueCode, setUniqueCode] = useState('');
    const [errorsInfo, setErrorsInfo] = useState({});
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.common);

    const handleChange = (e) => {
        const value = e.target.value;
        setUniqueCode(value);
        if (errorsInfo.unique_code) {
            setErrorsInfo({ ...errorsInfo, unique_code: '' });
        }
    };

    const validateUniqueCode = () => {
        let isValid = true; 
        let errors = {};

        if (!uniqueCode || uniqueCode.trim() === '') {
            errors.unique_code = 'Unique code is required';
            isValid = false;
        } 
        setErrorsInfo(errors);
        return isValid;
    };

    const uniqueCodeSubmit = async (e) => {
        e.preventDefault();
        if (validateUniqueCode()) {
            const res = await sendRequest(`/user/page/join`, "POST", { unique_code:uniqueCode });
            handleApiResponse(res);
            if (res.data.type === "success") {
                navigate(appRoutes.viewPageDetailPageRoute +'/'+ res.data.data.id);
            }else{
                toast.error(res.data.message);
            }
        }
    };

    return (
        <div className='outer bg-lightBrown'>
        <div className="form-container">
            <form className="login-form">
                <div className="icon-picture">
                <i className="fa-solid fa-code"></i>
                </div>

                <div className="text-center mb-11">
                    <h1 className="title mb-4">Unique Code</h1>
                </div>               
                <div className="mb-3">
                        <FloatingLabel label="Enter unique code">
                            <Form.Control
                                className="input-field form-control"
                                type="text"
                                placeholder=""
                                name="unique_code"
                                onChange={handleChange} 
                            />
                        </FloatingLabel>
                        <div className="error-message">{errorsInfo.unique_code}</div>
                    </div>
                    <div className='text-center mb-4'>
                    <Button className='w-100 btn-lg btn-primary shadow' onClick={uniqueCodeSubmit}
                            disabled={loading ? true : false}>
                            {
                                loading
                                    ?
                                    <span className="indicator-progress" style={{ display: 'block' }}>
                                        Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                    :
                                    <span className="indicator-label">Join</span>
                            }
                        </Button>
                    </div>
                <div className="">
                    <p onClick={() => navigate(appRoutes.selectPageTypePageRoute)} className='text-center small text-primary text-decoration-underline pointer'><i className="fa-solid fa-chevron-left small"></i> Back</p>
                </div>
            </form>
        </div>
    </div>
    );
};

export default JoinPage;
