import STRINGS from '../../common/strings/strings';

export function validateMusicValidation(musicData) {
    const { title, url } = musicData;
    let errors = {};
    let formValidCheck = true;

    // Validate title
    if ( title === '' || title === undefined){
        errors.title = STRINGS.titleReq; // Use title required string
        formValidCheck = false;
    }

    // Validate url
    if (url === '' || url === undefined) {
        errors.url = STRINGS.urlReq; // Use URL required string
        formValidCheck = false;
    } else {
        const generalUrlPattern = /^(https?:\/\/[^\s]+)/; // Example pattern for general URLs
        if (!generalUrlPattern.test(url)) {
            errors.url = STRINGS.invalidUrl; // Use invalid URL string
            formValidCheck = false;
        }
    }

    return { formValidCheck, errors };
}
