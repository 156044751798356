import STRINGS from '../../../common/strings/strings';
import Helper from '../../../apis/Helper';

export function validateUpcomingFormData(eventData, type, id=null) {
    let errorMsg = ''; let errors = {}; let formVaildCheck = true;

        const {title, date_time,  description,} = eventData;
        if (title === '' && id == null) {
            errorMsg = title === '' ? STRINGS.titleReq : '';
            errors.title = errorMsg;
            formVaildCheck = false;
        }
        if (date_time === '') {
            errorMsg = date_time === '' ? STRINGS.eventDateReq : '';
            errors.date_time = errorMsg;
            formVaildCheck = false;
        }       
        if (description === '' && id == null) {
            errorMsg = description === '' ? STRINGS.descriptionReq : '';
            errors.description = errorMsg;
            formVaildCheck = false;
        }

    return { formVaildCheck: formVaildCheck, errors: errors };
}