import React, { useState, useEffect } from 'react';
import { sendRequest } from "../../../apis/APIs";
import { useNavigate, useParams } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import moment from "moment";
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import { handleApiResponse } from "../../../apis/apiUtils";
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import appRoutes from '../../../configs/AppRoutes';
const EventList = () => {
    const [upcommingPlans, setUpcommingPlans] = useState({});
    const [pageDetail, setPageDetail] = useState({});
    const [difference, setDifference] = useState("");
    const params = useParams();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user, pageData } = useSelector((state) => state.common);

    useEffect(() => {
        getUpcommingPlans();
        getPageDetail();

    }, []);

    const getUpcommingPlans = async () => {
        try {
            let reqUrl = `/user/page/` + pageData.id + `/upcomming-plans`;
            const res = await sendRequest(reqUrl, "GET");
            if (res.data.type === "success") {
                setUpcommingPlans(res.data.data);
            }
        } catch (error) {
            console.log('Something went wrong');
        }
    };



    const getPageDetail = async () => {
        try {
            let reqUrl = `/user/page/` + pageData.id;
            const res = await sendRequest(reqUrl, "GET");
            if (res.data.type === "success") {
                setPageDetail(res.data.data);
                if (res.data.data.type === 1) {
                    const startDate = moment(res.data.data.anniversary_date);
                    const now = moment();
                    const years = now.diff(startDate, 'years');
                    const months = now.diff(startDate.add(years, 'years'), 'months');
                    setDifference(`${years} years and ${months} months`);
                }
            }
        } catch (error) {
            console.log('Something went wrong');
        }
    };



    const handleEventDelete = async (eventId) => {
        console.log(eventId)
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "Do you really want to delete this Event? This action cannot be undone.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
        });

        if (result.isConfirmed) {
            try {
                const reqUrl = `/user/page/event/delete/${eventId}`;
                const res = await sendRequest(reqUrl, "DELETE");
                handleApiResponse(res);
                if (res.data.type === "success") {
                    getPageDetail();
                } else {
                    toast.error(res.data.message || 'Failed to delete note');
                }
            } catch (error) {
                toast.error('Something went wrong while deleting gallery');
                dispatch(callCommonAction({ loading: false }));
            }
        } else {
            // If user cancels, do nothing
            console.log("Deletion canceled.");
        }
    };






    return (
        <div>
            <div className='px-3'>
                <div className="title-header">
                    <div>
                        <h5 className="mb-0"><i className="fa-solid fa-list-check"></i> Add New Event</h5>
                    </div>

                </div>
                <div className='mb-2'>
                    <button
                        className='btn add-item-btn w-100'
                        onClick={() => {
                            const route = `/page/${pageData.id}${appRoutes.createEventPageRoute}`;
                            navigate(route);
                        }}
                    > Add Event</button>
                </div>
                <div className=''>
                    <div className="title-header">
                        <div>
                            <h5 className="mb-0"><i className="fa-regular fa-calendar-days"></i> Event List</h5>
                        </div>

                    </div>
                    <div className='listing'>
                        {pageDetail.events && pageDetail.events.length > 0 ? (
                            pageDetail.events.map((item, index) => (
                                <div className='list-item' key={index}>
                                    <div className='d-flex align-items-center'>
                                        <div className='avatar flex-shrink-0 me-2'>
                                            <img src={item.favorite_gallery?.file ? `${process.env.REACT_APP_ASSET_ENDPOINT}/${item.favorite_gallery.file}` : process.env.PUBLIC_URL + '/assets/images/user-default.jpg'} width={'50px'} />
                                        </div>
                                        <div className='w-100'>
                                            <h2>{item.name}</h2>
                                            <p className='m-0 text-truncate'> {item.description}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <Dropdown className='custom-dropdown'>
                                            <Dropdown.Toggle as="button" className="btn">
                                                <i className="fa-solid fa-ellipsis-vertical"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => navigate(appRoutes.viewEventPageRoute + '/' + item.id)}><i className="fa-solid fa-eye me-1"></i> View</Dropdown.Item>
                                                {(user?.id === pageDetail?.created_by.id || user?.id === item.created_by) && (
                                                
                                                <Dropdown.Item onClick={() => navigate(appRoutes.updateEventPageRoute + '/' + item.id)}><i className="fa-solid fa-edit me-1"></i> Update</Dropdown.Item>
                                            )}
                                                
                                                {(user?.id === pageDetail?.created_by.id || user?.id === item.created_by) && (
                                                    <Dropdown.Item onClick={() => handleEventDelete(item.id)}>
                                                        <i className="fa-solid fa-trash me-1"></i> Delete
                                                    </Dropdown.Item>
                                            )}

                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No Data Available</p>
                        )}
                    </div>
                </div>
            </div>
            <div className="mt-4">
                <p onClick={() => navigate(pageData ? appRoutes.viewPageDetailPageRoute + '/' + pageData.id : appRoutes.selectPageTypePageRoute)} className='text-center small text-primary text-decoration-underline pointer'><i className="fa-solid fa-chevron-left small"></i> Back</p>
            </div>
        </div>
    );
};

export default EventList;
