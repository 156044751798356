import React, { useState } from "react";

const ThankYou = () => {
    return (
    <div>
      <p>You have successfully registerd. Please verify your email then login!</p>
    </div>
 );
};

export default ThankYou;
