import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { sendRequest } from "../../apis/APIs";
import { handleApiResponse } from "../../apis/apiUtils";
import { callCommonAction } from '../../redux/Common/CommonReducer';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { Button, FloatingLabel, Form, Dropdown } from 'react-bootstrap';
import appRoutes from '../../configs/AppRoutes';
import { validatePageFormData } from "./Validation";
import { toast } from 'react-toastify';

const CreateUpdateCouple = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [pageDetail, setPageDetail] = useState({ type: 2, cover_picture: '', name: '' });
    const [errorsInfo, setErrorsInfo] = useState({});
    const [errors, setErrors] = useState({});

    const [userList, setUserList] = useState([]);
    const dispatch = useDispatch();
    const { loading, user, loading2, pageData } = useSelector((state) => state.common);
    const [fileName, setFileName] = useState(''); // State to store the file name
    const [filePreview, setFilePreview] = useState(null); // State to store the file preview URL
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [memberDetails, setMemberDetails] = useState({});
    const [copySuccess, setCopySuccess] = useState('');
    const [inviteMember, setInviteMember] = useState();


    useEffect(() => {
        if (params.id) {
            getPageDetail();
        }
        getUserList();
    }, []);


    const copyToClipboard = (text, e) => {
        e.preventDefault(); // Prevent form submission
        navigator.clipboard.writeText(text)
            .then(() => {
                setCopySuccess('Code copied!');
                setTimeout(() => {
                    setCopySuccess('');
                }, 1000); // Hide the message after 1 second
            })
            .catch(err => setCopySuccess('Failed to copy code.'));
    };


    const handleChangeOptions = (event) => {
        // Get the selected options as an array of values
        const options = Array.from(event.target.selectedOptions, option => option.value);

        // Check if selected options exceed the limit
        if (options.length > 4) {
            alert("You can select a maximum of 4 options.");
            return;
        }

        // Convert array to comma-separated string and store in state
        setSelectedOptions(options);
        setPageDetail({
            ...pageDetail, // Spread the existing properties of pageDetail
            // members_invited: options.join(',') // Update the anniversary_date property
        });
    };


    const selectedArray = selectedOptions;
    const getUserList = async () => {
        try {
            let reqUrl = `/user/list`;
            const res = await sendRequest(reqUrl, "GET");
            if (res.data.type === "success") {
                setUserList(res.data.data);
            }
        } catch (error) {
            //toast.error('Something went wrong');
        }
    };

    const getPageDetail = async () => {
        try {
            let reqUrl = `/user/page/` + params.id;
            const res = await sendRequest(reqUrl, "GET");

            if (res.data.type === "success") {
                setPageDetail({
                    ...pageDetail,
                    name: res.data.data.name,
                    // members_invited: res.data.data.members_invited,
                    anniversary_date: res.data.data.anniversary_date,

                });
                setMemberDetails(res.data.data);
                setSelectedOptions(res.data.data.members_invited.split(','));
                setFilePreview(process.env.REACT_APP_ASSET_ENDPOINT + '/' + res.data.data.cover_picture);
            }
        } catch (error) {
            //toast.error('Something went wrong');
        }
    };



    const handleFileChange = (e) => {
        const file = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;

        if (file) {
            setFileName(file.name); // Update the file name in state
            setFilePreview(URL.createObjectURL(file)); // Create a preview URL and update the state
            setPageDetail({
                ...pageDetail, // Spread the existing properties of pageDetail
                cover_picture: file // Update the anniversary_date property
            });
        }
    };



    const handleChange = (e) => {
        // Create a copy of the pageDetail state
        const updatedPageDetail = { ...pageDetail };

        // Handle year validation if the changed input is anniversary_date
        if (e.target.name === 'anniversary_date') {
            const value = e.target.value;
            const year = value.split('-')[0]; // Extract the year from the value

            if (year.length > 4) {
                // Show an error if the year exceeds 4 digits
                setErrorsInfo((prevErrors) => ({
                    ...prevErrors,
                    anniversary_date: "Year must be 4 digits."
                }));
                return; // Prevent updating the state if the year is invalid
            } else {
                // Clear the error if the year is valid
                setErrorsInfo((prevErrors) => ({
                    ...prevErrors,
                    anniversary_date: ""
                }));
            }
        }

        // Update the pageDetail with the new value
        updatedPageDetail[e.target.name] = e.target.value;

        // Update the state with the new pageDetail
        setPageDetail(updatedPageDetail);

        // Call the function to check if the form is valid
        checkFormIsValid(e.target.name);
    };


    /** Validation implemented here */
    const checkFormIsValid = async () => {
        const res = validatePageFormData(pageDetail, pageDetail.type, params.id);
        setErrorsInfo(res.errors);
        return res.formVaildCheck;
    };


    const handleChangeEmail = (e) => {
        const value = e.target.value;
        setInviteMember({ ...inviteMember, email: value });
    };



    const savePageDetail = async (e) => {
        e.preventDefault();
        if (await checkFormIsValid("")) {
            try {
                const formData = new FormData();
                Object.keys(pageDetail).forEach((key) => {
                    formData.append(key, pageDetail[key]);
                });
                formData.append('cover_picture', filePreview);
                dispatch(callCommonAction({ loading: true }));
                let reqUrl = params.id ? `/user/page/update/` + params.id : `/user/page/create`;
                const res = await sendRequest(reqUrl, "POST", formData);
                dispatch(callCommonAction({ loading: false }));
                handleApiResponse(res);
                if (res.data.type === "success") {
                    localStorage.setItem('pageData', JSON.stringify(res.data.data));
                    dispatch(callCommonAction({ pageData: res.data.data }));
                    navigate(appRoutes.viewPageDetailPageRoute + '/' + res.data.data.id);
                }
            } catch (error) {
                //toast.error('Something went wrong');
                dispatch(callCommonAction({ loading: false }));
            }
        }
    };


    const updateMemberStatus = async (pageId, userId, status, isAdmin = false) => {
        try {
            let data = { page_id: pageId, user_id: userId, status: status };
            let reqUrl = `/user/page/request`;
            dispatch(callCommonAction({ loading1: true }));
            const res = await sendRequest(reqUrl, "POST", data);
            dispatch(callCommonAction({ loading1: false }));
            handleApiResponse(res);
            if (res.data.type === "success") {
                if (isAdmin) {
                    dispatch(callCommonAction({ pageData: '' }));
                    navigate(appRoutes.selectPageTypePageRoute)
                } else {
                    getPageDetail();
                }

            }
        } catch (error) {
            console.log('Something went wrong');
        }
    };


    const inviteFriend = async (e) => {
        e.preventDefault();
        if (inviteMember === undefined || inviteMember === "") {
            setErrors("Enter email address")
            return;
        }
        setErrors('')
        try {
            dispatch(callCommonAction({ loading2: true }));
            let reqUrl = `/user/joind-member-by-email`
            const res = await sendRequest(reqUrl, "POST", { ...inviteMember, page_id: pageData.id });

            dispatch(callCommonAction({ loading2: false }));
            handleApiResponse(res);
            if (res.data.type === "success") {
                localStorage.setItem('pageData', JSON.stringify(res.data.data));
                // dispatch(callCommonAction({ pageData: res.data.data }));
                getPageDetail();
            }
        } catch (error) {
            dispatch(callCommonAction({ loading: false }));
        }
    };


    const isUserJoined = memberDetails.member_joined?.some(member => {
        console.log('User ID:', user.id);
        console.log('meneber ID:', member.user_id);
        return member.user_id === user.id;
    });



    return (
        <div className='outer bg-lightBrown'>
            <div className="form-container">
                <form
                    className="create-page-form"
                    encType="multipart/form-data"
                    onSubmit={savePageDetail}>
                    <div className="icon-picture">
                        {filePreview ? (
                            <img src={filePreview} alt={fileName} className="" />
                        ) : (
                            <i className="fa-solid fa-user-group" />
                        )}
                    </div>
                    {params.id && (
                        <button type="button"
                            onClick={() => updateMemberStatus(pageData.id, user.id, 4, true)}
                            className="btn btn-danger btn-sm ms-2 leave-grp-btn"
                        ><i class="fa-solid fa-arrow-right-from-bracket me-1"></i> Leave group</button>
                    )}
                    {/* <div className="t-4 position-relative">
                        <div className='caption mb-4 px-3'>
                            <div className="d-flex flex-column position-relative copy-code">
                                <input type='text' className='form-control' value={memberDetails.unique_code} readOnly />
                                <button className='btn btn-link position-absolute right-0 top-0' onClick={(e) => copyToClipboard(memberDetails.unique_code, e)}>
                                    <i className="fa-solid fa-copy"></i>
                                </button>
                                {copySuccess && <p className='m-0 text-dark'>{copySuccess}</p>}
                            </div>
                        </div>
                    </div> */}
                    <div className="text-center mb-11">
                        <h1 className="title mb-4">{params.id ? 'Update' : 'Create'} Friend Page</h1>
                    </div>
                    {/* Image Upload Field */}
                    <div className="mb-3">
                        <Form.Group controlId="formFile" className="mb-3">
                            {/* <div className='uploadedFile my-3 text-center'>
                                {filePreview && <img src={filePreview} alt={fileName} className="image-preview" />}
                                {fileName && <p className="file-name m-0">{fileName}</p>}
                            </div> */}
                            <label className="custom-file-upload">
                                <input
                                    className="input-field form-control"
                                    type="file"
                                    name="cover_picture"
                                    accept="image/*"
                                    onChange={handleFileChange} // Attach the onChange handler
                                />
                                <i className="fa-solid fa-upload"></i> Upload Image
                            </label>
                        </Form.Group>
                        <div className="error-message">{errorsInfo.cover_picture}</div>
                    </div>

                    <div className="mb-3">
                        <FloatingLabel controlId="floatingDate" label="Enter group name">
                            <Form.Control
                                className="input-field form-control"
                                type="text"
                                placeholder="Enter group name"
                                name="name"
                                value={pageDetail.name}
                                onChange={handleChange}
                            />
                        </FloatingLabel>
                        <div className="error-message">{errorsInfo.name}</div>
                    </div>

                    {/* Date Picker Field */}
                    <div className="mb-3">
                        <FloatingLabel controlId="floatingDate" label="Add Significant Date (Group Anniversary)">
                            <Form.Control
                                className="input-field form-control"
                                type="date"
                                placeholder="Add Significant Date (Group Anniversary)"
                                name="anniversary_date"
                                value={pageDetail.anniversary_date}
                                onChange={handleChange}
                            />
                        </FloatingLabel>
                        <div className="error-message">{errorsInfo.anniversary_date}</div>
                    </div>
                    {/* <div className="mb-3">
                        <Form.Select className="input-field form-control" multiple aria-label="Default select example" onChange={handleChangeOptions} value={selectedArray}>
                            <option>Open this select menu</option>
                            {
                                userList.length > 0 ? (
                                    userList.map((user) => (
                                        <option key={user.id} value={user.id}>
                                            {user.name}
                                        </option>
                                    ))
                                ) : (
                                    <option value="">No options available</option>
                                )
                            }
                        </Form.Select>
                        <div className="error-message">{errorsInfo.members_invited}</div>
                    </div> */}
                    <div className="text-center mb-4">
                        <Button className='w-100 btn-lg btn-primary shadow' onClick={savePageDetail}
                            disabled={loading ? true : false}>
                            {
                                loading
                                    ?
                                    <span className="indicator-progress" style={{ display: 'block' }}>
                                        Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                    :
                                    <span className="indicator-label">{params.id ? 'Update' : 'Create'}</span>
                            }
                        </Button>
                    </div>


                    <hr className='my-5' />

                    <div className='bg-body border py-4 px-3 rounded-2 mb-4'>

                        <div className="t-4 position-relative">
                            <div className='caption px-3'>
                                <div className="d-flex flex-column position-relative copy-code">
                                    <input type='text' className='form-control' value={memberDetails.unique_code} readOnly />
                                    <button className='btn btn-link position-absolute right-0 top-0' onClick={(e) => copyToClipboard(memberDetails.unique_code, e)}>
                                        <i className="fa-solid fa-copy"></i>
                                    </button>
                                    {copySuccess && <p className='m-0 text-dark'>{copySuccess}</p>}
                                </div>
                            </div>
                        </div>
                        {user?.id === pageData?.created_by && (

                            <div className="text-center mt-4 mb-4 small">
                                OR
                            </div>
                        )}
                        {user?.id === pageData?.created_by && (


                            <div className="mb-3">
                                <FloatingLabel controlId="floatingDate" label="Email">
                                    <Form.Control
                                        className="input-field form-control"
                                        type="Email"
                                        placeholder="Email"
                                        name="Email"
                                        onChange={handleChangeEmail}
                                    />
                                </FloatingLabel>
                                {typeof errors === "string" && errors && (
                                    <div className="error-message">{errors}</div>
                                )}
                                <div className="text-center mb-4">
                                    <Button className='w-100 btn-lg btn-primary shadow mt-2' onClick={inviteFriend}
                                        disabled={loading2 ? true : false}>
                                        {
                                            loading2
                                                ?
                                                <span className="indicator-progress" style={{ display: 'block' }}>
                                                    Please wait...
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                                :
                                                <span className="indicator-label">Invite Friend</span>
                                        }
                                    </Button>
                                </div>

                            </div>
                        )}


                    </div>
                    {/* {user?.id === pageData?.created_by && (
                        <>
                            <div className="mb-3">
                                <FloatingLabel controlId="floatingDate" label="Email">
                                    <Form.Control
                                        className="input-field form-control"
                                        type="Email"
                                        placeholder="Email"
                                        name="Email"
                                        onChange={handleChangeEmail}
                                    />
                                </FloatingLabel>
                            </div>
                            <div className="text-center mb-4">
                                <Button className='w-100 btn-lg btn-primary shadow' onClick={inviteFriend}
                                    disabled={loading2 ? true : false}>
                                    {
                                        loading2
                                            ?
                                            <span className="indicator-progress" style={{ display: 'block' }}>
                                                Please wait...
                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                            :
                                            <span className="indicator-label">Invite Friend</span>
                                    }
                                </Button>
                            </div>
                        </>
                    )} */}


                </form>
            </div>


            <div>
                <div className="title-header">
                    <div>
                        <h5 className="mb-0"><i className="fa-solid fa-users"></i>Member List</h5>
                    </div>

                </div>
                {user?.id != pageData?.created_by && (

                    <div className='list-item'>
                        <div className='d-flex align-items-center'>
                            <div className='avatar flex-shrink-0 me-2'>
                                <img
                                    src={memberDetails?.created_by?.profile_picture ?
                                        `${process.env.REACT_APP_ASSET_ENDPOINT}/${memberDetails?.created_by?.profile_picture}` :
                                        process.env.PUBLIC_URL + '/assets/images/user-default.jpg'}
                                    width={'50px'}
                                />
                            </div>


                            <div className='w-100'>
                                <h2>{memberDetails?.created_by?.name} (Admin)</h2>

                                <p className='m-0'>
                                    <a href="#" className='link'>
                                        <i className="fa-solid fa-envelope me-1"></i> {memberDetails?.created_by?.email}
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                )}

                <div className='listing'>
                    {memberDetails.members_invited && memberDetails.members_invited.length > 0 ? (
                        memberDetails.members_invited.filter(item => item.status === 1).length > 0 ? (
                            memberDetails.members_invited.filter(item => item.status === 1).map((item, index) => (
                                <div className='list-item'>
                                    <div className='d-flex align-items-center'>
                                        <div className='avatar flex-shrink-0 me-2'>
                                            <img src={item.created_by.profile_picture ? `${process.env.REACT_APP_ASSET_ENDPOINT}/${item.created_by.profile_picture}` : process.env.PUBLIC_URL + '/assets/images/user-default.jpg'} width={'50px'} />
                                        </div>
                                        <div className='w-100'>
                                            <h2>{item.created_by.name}</h2>
                                            <p className='m-0'><a href="#" className='link'><i className="fa-solid fa-envelope me-1"></i> {item.created_by.email}</a></p>
                                        </div>
                                    </div>
                                    <div>
                                        {(user.id === memberDetails.created_by.id ||
                                            user.id === item.created_by.id) && (
                                                <Dropdown className='custom-dropdown'>
                                                    <Dropdown.Toggle as="button" className="btn">
                                                        <i className="fa-solid fa-ellipsis-vertical"></i>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        {item.status === 5 && (
                                                            <>
                                                                <Dropdown.Item onClick={() => updateMemberStatus(memberDetails.id, item.created_by.id, 1)}>
                                                                    <i className="fa-solid fa-check me-1"></i> Accept
                                                                </Dropdown.Item>
                                                                <Dropdown.Item onClick={() => updateMemberStatus(memberDetails.id, item.created_by.id, 2)}>
                                                                    <i className="fa-solid fa-times me-1"></i> Reject
                                                                </Dropdown.Item>
                                                            </>
                                                        )}
                                                        {memberDetails.member_joined.some(member => member.user_id === user.id && item.status === 1) && (
                                                            <Dropdown.Item onClick={() => updateMemberStatus(memberDetails.id, item.created_by.id, 3)}>
                                                                <i className="fa-solid fa-sign-out-alt me-1"></i> Leave
                                                            </Dropdown.Item>
                                                        )}
                                                        {user.id === memberDetails.created_by.id && item.status === 1 && (
                                                            <Dropdown.Item onClick={() => updateMemberStatus(memberDetails.id, item.created_by.id, 4)}>
                                                                <i className="fa-solid fa-trash me-1"></i> Remove
                                                            </Dropdown.Item>
                                                        )}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            )}
                                    </div>


                                </div>
                            ))
                        ) : (
                            <p>No Data Available</p> // Message when no members match the status condition
                        )
                    ) : (
                        <p>No Data Available</p> // Message when members_invited is empty
                    )}
                </div>
            </div>

            <div>
                <div className="title-header">
                    <div>
                        <h5 className="mb-0"><i className="fa-solid fa-users"></i> Request List</h5>
                    </div>
                </div>
                <div className='listing'>

                    {memberDetails.members_invited && memberDetails.members_invited.length > 0 ? (
                        memberDetails.members_invited.filter(item => item.status === 0 || item.status === 5).length > 0 ? (
                            memberDetails.members_invited.filter(item => (item.status === 0 || item.status === 5)).map((item, index) => (
                                <div className='list-item' key={index}>
                                    <div className='d-flex align-items-center'>
                                        <div className='avatar flex-shrink-0 me-2'>
                                            <img
                                                src={item.created_by.profile_picture ?
                                                    `${process.env.REACT_APP_ASSET_ENDPOINT}/${item.created_by.profile_picture}` :
                                                    process.env.PUBLIC_URL + '/assets/images/user-default.jpg'}
                                                width={'50px'}
                                            />
                                        </div>


                                        <div className='w-100'>
                                            <h2>{item.created_by.name}</h2>

                                            <p className='m-0'>
                                                <a href="#" className='link'>
                                                    <i className="fa-solid fa-envelope me-1"></i> {item.created_by.email}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <Dropdown className='custom-dropdown'>
                                            <Dropdown.Toggle as="button" className="btn">
                                                <i className="fa-solid fa-ellipsis-vertical"></i>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {
                                                    user.id === memberDetails.created_by.id && item.status === 5 ? (
                                                        <>
                                                            <Dropdown.Item onClick={() => updateMemberStatus(memberDetails.id, item.created_by.id, 1)}>
                                                                <i className="fa-solid fa-check me-1"></i> Accept
                                                            </Dropdown.Item>
                                                            <Dropdown.Item onClick={() => updateMemberStatus(memberDetails.id, item.created_by.id, 2)}>
                                                                <i className="fa-solid fa-times me-1"></i> Reject
                                                            </Dropdown.Item>
                                                        </>
                                                    ) : null
                                                }
                                                {
                                                    user.id !== memberDetails.created_by.id && item.status === 1 ? (
                                                        <Dropdown.Item onClick={() => updateMemberStatus(memberDetails.id, item.created_by.id, 3)}>
                                                            <i className="fa-solid fa-sign-out-alt me-1"></i> Leave
                                                        </Dropdown.Item>
                                                    ) : null
                                                }
                                                {
                                                    user.id === memberDetails.created_by.id && item.status === 1 ? (
                                                        <Dropdown.Item onClick={() => updateMemberStatus(memberDetails.id, item.created_by.id, 4)}>
                                                            <i className="fa-solid fa-trash me-1"></i> Remove
                                                        </Dropdown.Item>
                                                    ) : null
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No Data Available</p> // Message when no members match the status condition
                        )
                    ) : (
                        <p>No Data Available</p> // Message when members_invited is empty
                    )}
                </div>
            </div>
            <div className="">
                <p onClick={() => navigate(appRoutes.selectPageTypePageRoute)} className='text-center small text-primary text-decoration-underline pointer'><i className="fa-solid fa-chevron-left small"></i> Back</p>
            </div>
        </div>
    );
};

export default CreateUpdateCouple;
