import { useState, useEffect } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import appRoutes from '../../configs/AppRoutes';
import { sendRequest } from "../../apis/APIs";
import { useSelector, useDispatch } from 'react-redux';
import { Button, FloatingLabel, Form, Modal } from 'react-bootstrap';
import { callCommonAction } from '../../redux/Common/CommonReducer';

const SelectType = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.common);

    const [show, setShow] = useState(false);
    const [errorsInfo, setErrorsInfo] = useState({});

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    useEffect(() => {
        getPageData();
    }, []);
    const getPageData = async () => {
       // e.preventDefault();
        //if (validateOtp()) {
            const res = await sendRequest(`/user/get-page-data`, "GET",);
            if (res.data.type === "success") {
                
                if (res.data.page_data) {
                    dispatch(callCommonAction({ pageData: res.data.page_data }));
                    localStorage.setItem('pageData', JSON.stringify(res.data.page_data));
                    navigate(appRoutes.viewPageDetailPageRoute + '/' + res.data.page_data.id);
                } else {
                    navigate(appRoutes.selectPageTypePageRoute);
                }

            } else {
                //toast.error(res.data.message || 'Otp not valid');
            }
        //}
    };


    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='p-3'>
                        {/* <div className='imageBox shadow rounded-4 mb-3'>
                            <img className='' src={process.env.PUBLIC_URL + '/assets/images/couple-front.jpg'} />
                            <Button className='w-100 btn-lg shadow fs-6' variant="light" onClick={() => navigate(appRoutes.createCouplePageRoute)}><i className="fa-solid fa-user-group me-1"></i> Create Couple</Button>
                        </div> */}
                        <div className='imageBox shadow rounded-4 mb-3'>
                            <img className='' src={process.env.PUBLIC_URL + '/assets/images/friends.jpg'} />
                            <Button className='w-100 btn-lg shadow fs-6' variant="light" onClick={() => navigate(appRoutes.createFriendPageRoute)}><i className="fa-solid fa-users me-1"></i> Create Friend</Button>
                        </div>
                        <div className='pt-2'>
                            <Button className='w-100 btn-lg btn-primary shadow btn btn-primary fs-6' variant="dark" onClick={() => navigate(appRoutes.joinPageRoute)}><i className="fa-solid fa-user-plus me-1"></i> Join Page</Button>
                        </div>
                    </div>
                </div>
            </div>  
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title className='h5'>Unique Code</Modal.Title>
                </Modal.Header>
                <Modal.Body className='py-4'>
                    <div className="mb-3">
                        <FloatingLabel label="Enter unique code">
                            <Form.Control
                                className="input-field form-control"
                                type="text"
                                placeholder=""
                                name="id"
                            />
                        </FloatingLabel>
                        <div className="error-message">{errorsInfo.id}</div>
                    </div>
                    <div className='text-center'>
                        <Button variant="primary" onClick={handleClose}>
                            Save Changes
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SelectType;
