import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { sendRequest } from "../../apis/APIs";
import { handleApiResponse } from "../../apis/apiUtils";
import { callCommonAction } from '../../redux/Common/CommonReducer';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { Button, FloatingLabel, Form, Dropdown } from 'react-bootstrap';
import appRoutes from '../../configs/AppRoutes';
import { validatePageFormData } from "./Validation";
import { toast } from 'react-toastify';


const CreateUpdateCouple = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [pageDetail, setPageDetail] = useState({ type: 1, cover_picture: '', anniversary_date: '' });
    const [memberDetails, setMemberDetails] = useState({});
    const [inviteMember, setInviteMember] = useState();
    const [errorsInfo, setErrorsInfo] = useState({});
    const dispatch = useDispatch();
    const { loading, pageData, user, loading3, loading2 } = useSelector((state) => state.common);
    const [fileName, setFileName] = useState(''); // State to store the file name
    const [filePreview, setFilePreview] = useState(null); // State to store the file preview URL
    const [copySuccess, setCopySuccess] = useState('');
    const pageCover = `${process.env.REACT_APP_ASSET_ENDPOINT}/${pageDetail.cover_picture}`;

    useEffect(() => {
        if (params.id) {
            getPageDetail();
        }
    }, []);


    const copyToClipboard = (text, e) => {
        e.preventDefault(); // Prevent form submission
        navigator.clipboard.writeText(text)
            .then(() => {
                setCopySuccess('Code copied!');
                setTimeout(() => {
                    setCopySuccess('');
                }, 1000); // Hide the message after 1 second
            })
            .catch(err => setCopySuccess('Failed to copy code.'));
    };


    const getPageDetail = async () => {
        try {
            let reqUrl = `/user/page/` + params.id;
            const res = await sendRequest(reqUrl, "GET");
            if (res.data.type === "success") {
                setPageDetail({
                    ...pageDetail,
                    anniversary_date: res.data.data.anniversary_date,
                    cover_picture: res.data.data.cover_picture,

                });
                setMemberDetails(res.data.data);
                setFilePreview(process.env.REACT_APP_ASSET_ENDPOINT + '/' + res.data.data.cover_picture);
            }
        } catch (error) {
            toast.error('Something went wrong');
        }
    };

    const updateMemberStatus = async (pageId, userId, status) => {
        try {
            let data = { page_id: pageId, user_id: userId, status: status };
            let reqUrl = `/user/page/request`;
            dispatch(callCommonAction({ loading1: true }));
            const res = await sendRequest(reqUrl, "POST", data);
            dispatch(callCommonAction({ loading1: false }));
            handleApiResponse(res);
            if (res.data.type === "success") {
                getPageDetail();
            }
        } catch (error) {
            console.log('Something went wrong');
        }
    };


    const handleFileChange = (e) => {
        const file = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
        if (file) {
            setFileName(file.name); // Update the file name in state
            setFilePreview(URL.createObjectURL(file)); // Create a preview URL and update the state
            setPageDetail({
                ...pageDetail, // Spread the existing properties of pageDetail
                cover_picture: file // Update the anniversary_date property
            });
        }
    };

    const handleChange = (e) => {
        const value = e.target.value;
        console.log(value, "value");
        setPageDetail({
            ...pageDetail, // Spread the existing properties of pageDetail
            anniversary_date: value // Update the anniversary_date property
        });
        if (errorsInfo.anniversary_date) {
            setErrorsInfo({ ...errorsInfo, anniversary_date: '' });
        }
        checkFormIsValid(e.target.name);
    };

    const handleChangeEmail = (e) => {
        const value = e.target.value;
        console.log(value, "value");
        setInviteMember({ ...inviteMember, email: value });
    };

    /** Validation implemented here */
    const checkFormIsValid = async () => {
        const res = validatePageFormData(pageDetail, pageDetail.type);
        setErrorsInfo(res.errors);
        return res.formVaildCheck;
    };


    const savePageDetail = async (e) => {
        e.preventDefault();
        if (await checkFormIsValid("")) {
            try {
                const formData = new FormData();
                Object.keys(pageDetail).forEach((key) => {
                    formData.append(key, pageDetail[key]);
                });
                formData.append('cover_picture', filePreview);

                dispatch(callCommonAction({ loading3: true }));
                let reqUrl = params.id ? `/user/page/update/` + params.id : `/user/page/create`;
                const res = await sendRequest(reqUrl, "POST", formData);
                dispatch(callCommonAction({ loading3: false }));
                handleApiResponse(res);
                if (res.data.type === "success") {
                    localStorage.setItem('pageData', JSON.stringify(res.data.data));
                    dispatch(callCommonAction({ pageData: res.data.data }));
                    navigate(appRoutes.viewPageDetailPageRoute + '/' + res.data.data.id);
                }
            } catch (error) {
                //toast.error('Something went wrong');

                dispatch(callCommonAction({ loading3: false }));
            }
        }
    };


    const inviteFriend = async (e) => {
        e.preventDefault();
        if (inviteMember == undefined) {
            setErrorsInfo("Email is required")
            return
        }
        setErrorsInfo("");  // Clear errors on valid input
        try {
            dispatch(callCommonAction({ loading2: true }));
            let reqUrl = `/user/joind-member-by-email`
            const res = await sendRequest(reqUrl, "POST", { ...inviteMember, page_id: pageData.id });
            console.log(res, "res-----------")
            dispatch(callCommonAction({ loading2: false }));
            handleApiResponse(res);
            if (res.data.type === "success") {
                localStorage.setItem('pageData', JSON.stringify(res.data.data));
                dispatch(callCommonAction({ pageData: res.data.data }));
            }
            if (res.data.type === "error") {
                toast.error(res.data.message)

            }
        } catch (error) {
            toast.error(error.data.message);
            dispatch(callCommonAction({ loading: false }));
        }
    };

    return (
        <div className='outer bg-lightBrown'>
            <div className="form-container">
                <form
                    className="create-page-form"
                    encType="multipart/form-data"
                    onSubmit={savePageDetail}>

                    <div className="icon-picture">
                        {filePreview ? (
                            <img src={filePreview} alt={fileName} className="" />
                        ) : (
                            <i className="fa-solid fa-user-group" />
                        )}
                    </div>
                    <div className="text-center mb-11">
                        <h1 className="title mb-4">{params.id ? 'Update' : 'Create'} Couple Page</h1>
                    </div>

                    {/* Image Upload Field */}
                    <div className="mb-3">
                        <Form.Group controlId="formFile" className="mb-3">
                            {/* <div className='uploadedFile my-3 text-center'>
                                {filePreview && <img src={filePreview} alt={fileName} className="image-preview" />}
                                {fileName && <p className="file-name m-0">{fileName}</p>}
                            </div> */}

                            <label className="custom-file-upload">
                                <input
                                    className="input-field form-control"
                                    type="file"
                                    name="cover_picture"
                                    accept="image/*"
                                    onChange={handleFileChange} // Attach the onChange handler
                                />
                                <i className="fa-solid fa-upload"></i> Upload Image
                            </label>
                        </Form.Group>
                        <div className="error-message">{errorsInfo.cover_picture}</div>
                    </div>

                    {/* Date Picker Field */}
                    <div className="mb-3">
                        <FloatingLabel controlId="floatingDate" label="Add Significant Date (Group Anniversary)">
                            <Form.Control
                                className="input-field form-control"
                                type="date"
                                placeholder="Add Significant Date (Group Anniversary)"
                                name="anniversary_date"
                                value={pageDetail.anniversary_date}
                                onChange={handleChange}
                                onKeyDown={(e) => e.preventDefault()} // Prevent manual input
                            />
                        </FloatingLabel>
                        <div className="error-message">{errorsInfo.anniversary_date}</div>
                    </div>

                    <div className="text-center mb-4">
                        <Button className='w-100 btn-lg btn-primary shadow' onClick={savePageDetail}
                            disabled={loading3 ? true : false}>
                            {
                                loading3
                                    ?
                                    <span className="indicator-progress" style={{ display: 'block' }}>
                                        Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                    :
                                    <span className="indicator-label">{params.id ? 'Update' : 'Create'}</span>
                            }
                        </Button>
                    </div>

                    <hr className='my-5' />

                    <div className='bg-body border py-4 px-3 rounded-2 mb-4'>
                        <div className="justify-content-center pt-0 text-center title-header">
                            <div>
                                <h5 className="mb-0">Invite Friend</h5>
                            </div>
                        </div>
                        <div className="t-4 position-relative">
                            <div className='caption px-3'>
                                <div className="d-flex flex-column position-relative copy-code">
                                    <input type='text' className='form-control' value={memberDetails.unique_code} readOnly />
                                    <button className='btn btn-link position-absolute right-0 top-0' onClick={(e) => copyToClipboard(memberDetails.unique_code, e)}>
                                        <i className="fa-solid fa-copy"></i>
                                    </button>
                                    {copySuccess && <p className='m-0 text-dark'>{copySuccess}</p>}
                                </div>
                            </div>
                        </div>

                        <div className="text-center mt-4 mb-4 small">
                            OR
                        </div>


                        <div className="mb-3">
                            <FloatingLabel controlId="floatingDate" label="Email">
                                <Form.Control
                                    className="input-field form-control"
                                    type="Email"
                                    placeholder="Email"
                                    name="Email"
                                    onChange={handleChangeEmail}
                                />
                            </FloatingLabel>
                            {typeof errorsInfo === "string" && errorsInfo && (
                                <div className="error-message">{errorsInfo}</div>
                            )}

                        </div>

                        <div className="text-center mb-2">
                            <Button className='w-100 btn-lg btn-primary shadow' onClick={inviteFriend}
                                disabled={loading2 ? true : false}>
                                {
                                    loading2
                                        ?
                                        <span className="indicator-progress" style={{ display: 'block' }}>
                                            Please wait...
                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                        :
                                        <span className="indicator-label">Invite Friend</span>
                                }
                            </Button>
                        </div>
                    </div>




                </form>
            </div>
            <div>
                <div className="title-header">
                    <div>
                        <h5 className="mb-0"><i className="fa-solid fa-users"></i>Member List</h5>
                    </div>

                </div>
                <div className='listing'>
                    {memberDetails.members_invited && memberDetails.members_invited.length > 0 ? (
                        memberDetails.members_invited.filter(item => item.status === 1).length > 0 ? (
                            memberDetails.members_invited.filter(item => item.status === 1).map((item, index) => (
                                <div className='list-item'>
                                    <div className='d-flex align-items-center'>
                                        <div className='avatar flex-shrink-0 me-2'>
                                            <img src={item.created_by.profile_picture ? `${process.env.REACT_APP_ASSET_ENDPOINT}/${item.created_by.profile_picture}` : process.env.PUBLIC_URL + '/assets/images/user-default.jpg'} width={'50px'} />
                                        </div>
                                        <div className='w-100'>
                                            <h2>{item.created_by.name}</h2>
                                            <p className='m-0'><a href="#" className='link'><i className="fa-solid fa-envelope me-1"></i> {item.created_by.email}</a></p>
                                        </div>
                                    </div>
                                    <div>
                                        <Dropdown className='custom-dropdown'>
                                            <Dropdown.Toggle as="button" className="btn" >
                                                <i className="fa-solid fa-ellipsis-vertical"></i>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {
                                                    user.id === memberDetails.created_by.id && item.status === 5 ?
                                                        <>
                                                            <Dropdown.Item onClick={() => updateMemberStatus(memberDetails.id, item.created_by.id, 1)}><i className="fa-solid fa-check me-1"></i> Accept</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => updateMemberStatus(memberDetails.id, item.created_by.id, 2)}><i className="fa-solid fa-times me-1"></i> Reject</Dropdown.Item>
                                                        </>
                                                        : ''
                                                }
                                                {
                                                    user.id !== memberDetails.created_by.id && item.status === 1
                                                        ?
                                                        <Dropdown.Item onClick={() => updateMemberStatus(memberDetails.id, item.created_by.id, 3)}><i className="fa-solid fa-sign-out-alt me-1"></i> Leave</Dropdown.Item>
                                                        : ''
                                                }
                                                {
                                                    user.id === memberDetails.created_by.id && item.status === 1 ?
                                                        <>
                                                            <Dropdown.Item onClick={() => updateMemberStatus(memberDetails.id, item.created_by.id, 4)}><i className="fa-solid fa-trash me-1"></i> Remove</Dropdown.Item>
                                                        </>
                                                        : ''
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No Data Available</p> // Message when no members match the status condition
                        )
                    ) : (
                        <p>No Data Available</p> // Message when members_invited is empty
                    )}
                </div>
            </div>

            <div>
                <div className="title-header">
                    <div>
                        <h5 className="mb-0"><i className="fa-solid fa-users"></i> Request List</h5>
                    </div>
                </div>
                <div className='listing'>
                    {memberDetails.members_invited && memberDetails.members_invited.length > 0 ? (
                        memberDetails.members_invited.filter(item => item.status === 0).length > 0 ? (
                            memberDetails.members_invited.filter(item => item.status === 0).map((item, index) => (
                                <div className='list-item' key={index}>
                                    <div className='d-flex align-items-center'>
                                        <div className='avatar flex-shrink-0 me-2'>
                                            <img
                                                src={item.created_by.profile_picture ?
                                                    `${process.env.REACT_APP_ASSET_ENDPOINT}/${item.created_by.profile_picture}` :
                                                    process.env.PUBLIC_URL + '/assets/images/user-default.jpg'}
                                                width={'50px'}
                                            />
                                        </div>
                                        <div className='w-100'>
                                            <h2>{item.created_by.name}</h2>
                                            <p className='m-0'>
                                                <a href="#" className='link'>
                                                    <i className="fa-solid fa-envelope me-1"></i> {item.created_by.email}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <Dropdown className='custom-dropdown'>
                                            <Dropdown.Toggle as="button" className="btn">
                                                <i className="fa-solid fa-ellipsis-vertical"></i>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {
                                                    user.id === memberDetails.created_by.id && item.status === 5 ? (
                                                        <>
                                                            <Dropdown.Item onClick={() => updateMemberStatus(memberDetails.id, item.created_by.id, 1)}>
                                                                <i className="fa-solid fa-check me-1"></i> Accept
                                                            </Dropdown.Item>
                                                            <Dropdown.Item onClick={() => updateMemberStatus(memberDetails.id, item.created_by.id, 2)}>
                                                                <i className="fa-solid fa-times me-1"></i> Reject
                                                            </Dropdown.Item>
                                                        </>
                                                    ) : null
                                                }
                                                {
                                                    user.id !== memberDetails.created_by.id && item.status === 1 ? (
                                                        <Dropdown.Item onClick={() => updateMemberStatus(memberDetails.id, item.created_by.id, 3)}>
                                                            <i className="fa-solid fa-sign-out-alt me-1"></i> Leave
                                                        </Dropdown.Item>
                                                    ) : null
                                                }
                                                {
                                                    user.id === memberDetails.created_by.id && item.status === 1 ? (
                                                        <Dropdown.Item onClick={() => updateMemberStatus(memberDetails.id, item.created_by.id, 4)}>
                                                            <i className="fa-solid fa-trash me-1"></i> Remove
                                                        </Dropdown.Item>
                                                    ) : null
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No Data Available</p> // Message when no members match the status condition
                        )
                    ) : (
                        <p>No Data Available</p> // Message when members_invited is empty
                    )}
                </div>
            </div>
            <div className="">
                <p onClick={() => navigate(pageData ? appRoutes.viewPageDetailPageRoute + '/' + pageData.id : appRoutes.selectPageTypePageRoute)} className='text-center small text-primary text-decoration-underline pointer'><i className="fa-solid fa-chevron-left small"></i>Back</p>

            </div>
        </div>
    );
};

export default CreateUpdateCouple;
