import React, { useState ,useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { sendRequest } from "../../../apis/APIs";
import { handleApiResponse } from "../../../apis/apiUtils";
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import { validateRegisterFormData } from "../Login/Validation";
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, FloatingLabel, Form } from 'react-bootstrap';
import appRoutes from '../../../configs/AppRoutes';

const Login = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [userDetail, setUserDetail] = useState({ email: '', password: '', nfc_tag: params.id });
    const [errorsInfo, setErrorsInfo] = useState({});
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.common);

       // Check for valid ID on component mount
       useEffect(() => {
        if (!params.id || params.id === 'undefined') {
            navigate(appRoutes.loginPageRoute); // Redirect to login page or another appropriate page
        } else {
            setUserDetail((prev) => ({ ...prev, nfc_tag: params.id }));
        }
    }, [params.id, navigate]);

    const checkFormIsValid = async (fieldName) => {
        const res = validateRegisterFormData(userDetail, fieldName);
        if (fieldName) {
            fieldName = fieldName.trim();
            let err = res.errors[fieldName];
            if (typeof err != "undefined") {
                errorsInfo[fieldName] = err;
            } else {
                errorsInfo[fieldName] = "";
            }
            setErrorsInfo({ ...errorsInfo });
        } else {
            setErrorsInfo({ ...res.errors });
        }
        return res.formVaildCheck;
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserDetail({ ...userDetail, [name]: value });
    };

    const loginUserSubmit = async (e) => {
        e.preventDefault();
        if (await checkFormIsValid("")) {
            dispatch(callCommonAction({ loading: true }));
            const res = await sendRequest(`/user/login`, "POST", userDetail);
            dispatch(callCommonAction({ loading: false }));
            handleApiResponse(res);
            if (res.data.type === "success") {
                //toast.success('Naviagte to login page');
                localStorage.setItem("email", userDetail.email);
                navigate(appRoutes.otpPageRoute);
            } else {
                toast.error(res.data.message || 'Login failed');
            }
        }

    };

    return (
        <div className='siteWidth gap-top-negative'>
            <div className='d-flex flex-column justify-content-center outer bg-light position-relative'>
                <img className='top-right position-absolute' src={process.env.PUBLIC_URL + '/assets/images/top-right-shape.png'}></img>
                <div className="form-container">
                    <form
                        className="login-form"
                        onSubmit={loginUserSubmit}>
                        <div className="text-center mb-11">
                            <h1 className="title mb-4">Sign In</h1>
                            <p className='mb-5'>Welcome back you’ve
                                been missed!</p>
                        </div>
                        <div className="mb-3">
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Email address"
                            >
                                <Form.Control className="input-field form-control" type="email"
                                    placeholder="Email"
                                    name="email"
                                    value={userDetail.email}
                                    onChange={handleChange} />
                            </FloatingLabel>
                            <div className="error-message">{errorsInfo.email}</div>
                        </div>
                        <div className="mb-3">
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Password">
                                <Form.Control className="input-field form-control"
                                    type="password"
                                    placeholder="Password"
                                    name="password"
                                    value={userDetail.password}
                                    onChange={handleChange}
                                    autoComplete="off" />
                            </FloatingLabel>
                            <div className="error-message">{errorsInfo.password}</div>
                        </div>
                        <div className="mb-5">
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault(); // Prevent the default anchor behavior
                                    navigate(`${appRoutes.forgotPasswordPageRoute}/${params.id}`); 
                                }}><p className='text-end small text-primary text-decoration-underline pointer'>Forgot your password?</p></a>
                        </div>
                        <Form.Control className="input-field form-control"
                            type="hidden"
                            name="nfc_tag"
                            value={params.id}
                        />
                        <div className="text-center mb-4">
                            <Button onClick={loginUserSubmit} className='w-100 btn-lg btn-primary shadow'
                                disabled={loading ? true : false}>
                                {
                                    loading
                                        ?
                                        <span className="indicator-progress" style={{ display: 'block' }}>
                                            Please wait...
                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                        :
                                        <span className="indicator-label">Sign In</span>
                                }
                            </Button>
                        </div>
                        <div className="">
                            <p onClick={() => navigate(appRoutes.registerPageRoute + "/" + params.id)} className='text-center small text-primary text-decoration-underline pointer'>Create new account</p>
                        </div>
                    </form>
                </div>
                <img className='bottom-left position-absolute' src={process.env.PUBLIC_URL + '/assets/images/bottom-left-shape.png'}></img>
            </div>
        </div>

    );
};

export default Login;
