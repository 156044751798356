import React, { useState, useEffect } from 'react';
import { sendRequest } from "../../../apis/APIs";
import { useNavigate, useParams } from 'react-router-dom';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import { handleApiResponse } from "../../../apis/apiUtils";
import { useSelector, useDispatch } from 'react-redux';
import appRoutes from '../../../configs/AppRoutes';
import { Button, FloatingLabel, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

const Create = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, user, pageData } = useSelector((state) => state.common);
    const [fields, setFields] = useState([{ id: '', title: '' }]); // Initial state with title and id fields
    const [listTitle, setListTitle] = useState(''); // State for the list title
    const [errors, setErrors] = useState({ titleError: '', fieldErrors: [] });
    const handleAddField = () => {
        setFields([...fields, { id: '', title: '' }]); // Add new field with blank title and id
    };



    const validateInputs = () => {
        let isValid = true;
        let titleError = '';
        const fieldErrors = [];

        if (!listTitle.trim()) {
            titleError = 'List title is required.';
            isValid = false;
        }

        fields.forEach((field, index) => {
            if (!field.title.trim()) {
                fieldErrors[index] = 'Item title is required.';
                isValid = false;
            } else {
                fieldErrors[index] = '';
            }
        });

        setErrors({ titleError, fieldErrors });
        return isValid;
    };

    const handleRemoveField = (index) => {
        // Remove the field at the specified index
        const updatedFields = fields.filter((_, i) => i !== index);
        setFields(updatedFields); // Update the state with the new fields array
    };

    const handleFieldChange = (index, field, value) => {
        const updatedFields = [...fields];
        updatedFields[index][field] = value; // Update specific field value
        setFields(updatedFields); // Update state
    };

    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateInputs()) {
            return;
        }
        // Create an object to store the formatted data
        const formattedData = {};
        formattedData.title = listTitle;

        fields.forEach((field, index) => {
            formattedData[`item[${index}][title]`] = field.title;
            formattedData[`item[${index}][id]`] = field.id;
        });


        // Convert the object to FormData for the API request
        const formData = new FormData();
        for (const [key, value] of Object.entries(formattedData)) {
            formData.append(key, value);
        }

        try {
            dispatch(callCommonAction({ loading: true }));
            const reqUrl = `/user/page/${pageData.id}/bucket-list/create`;
            const res = await sendRequest(reqUrl, "POST", formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });

            dispatch(callCommonAction({ loading: false }));
            handleApiResponse(res);

            if (res.data.type === "success") {
                navigate(`${appRoutes.viewPageDetailPageRoute}/${pageData.id}`);
            } else {
                toast.error(res.data.message || 'Failed to Create list');
            }
        } catch (error) {
            toast.error('Something went wrong while sending message');
            dispatch(callCommonAction({ loading: false }));
            console.error(error);
        }
    };




    return (
        <div>
            <div className='px-3'>
                <div class="title-header mb-3"><div><h5 class="mb-0"><i class="fa-solid fa-list-check"></i> Create Bucket List</h5></div></div>
                <div className="">
                    <form className="bg-lightBrown border create-page-form px-3 py-3 rounded-2" encType="multipart/form-data">
                        <div className="mb-3">
                            <FloatingLabel controlId="floatingTitle" label="List Title">
                                <Form.Control
                                    className="input-field form-control"
                                    type="text"
                                    placeholder="Enter Bucket list title"
                                    name="title"
                                    value={listTitle}
                                    onChange={(e) => setListTitle(e.target.value)} // Update title state
                                />
                            </FloatingLabel>
                            {errors.titleError && <div className="error-message text-danger">{errors.titleError}</div>}
                            <div className="error-message"></div>
                        </div>
                        <div className="addbkt">
                            {fields.map((field, index) => (
                                <div key={index} className="mb-3 form-group bkt-item">
                                    <div className="d-flex align-items-center">
                                        <FloatingLabel className='w-100 me-2' controlId={`floatingItemTitle-${index}`} label={`Item Title ${index + 1}`}>
                                            <Form.Control
                                                className="input-field form-control"
                                                type="text"
                                                id={`field-title-${index}`}
                                                placeholder="Enter item title"
                                                name={`item[${index}][title]`}
                                                value={field.title}
                                                onChange={(e) => handleFieldChange(index, 'title', e.target.value)}
                                            />
                                        </FloatingLabel>
                                        {errors.fieldErrors[index] && <div className="error-message text-danger">{errors.fieldErrors[index]}</div>}
                                        <Form.Control
                                            className="input-field form-control"
                                            type="hidden"
                                            id={`field-id-${index}`}
                                            placeholder="Enter item ID"
                                            name={`item[${index}][id]`}
                                            value={field.uniqueId}
                                            onChange={(e) => handleFieldChange(index, 'uniqueId', e.target.value)}
                                        />
                                        <button type="button" onClick={handleAddField} className="btn py-0 px-1 addbkt-btn">
                                            <img src={process.env.PUBLIC_URL + '/assets/images/plus-icon.svg'} />
                                        </button>
                                        {/* Show the cross icon only if it's not the first field */}
                                        {index > 0 && (
                                            <button
                                                type="button"
                                                onClick={() => handleRemoveField(index)}
                                                className="btn py-0 px-1"
                                            >
                                                <img src={process.env.PUBLIC_URL + '/assets/images/x-mark-red.svg'} alt="Remove" />
                                            </button>
                                        )}
                                    </div>
                                    <div className="error-message w-100"></div>
                                </div>
                            ))}

                        </div>

                        <div className="text-center mb-0">
                            <Button className='w-100 btn-lg btn-primary shadow' onClick={handleSubmit}
                                disabled={loading ? true : false}>
                                {
                                    loading ? <span className="indicator-progress" style={{ display: 'block' }}>
                                        Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                        : <span className="indicator-label">Save</span>
                                }
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Create;
