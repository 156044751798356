import React from 'react'

const VerifyEmail = () => {
  return (
    <div>
      <p>Email is verified!</p>
    </div>
  )
}

export default VerifyEmail
