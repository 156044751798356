import STRINGS from '../../common/strings/strings';
import Helper from '../../apis/Helper';

export function validateUserFormData(userDetail) {
    let errorMsg = ''; let errors = {}; let formVaildCheck = true;

        const {name} = userDetail;
        
        if (name === '') {
            errorMsg = name === '' ? STRINGS.nameReq : '';
            errors.name = errorMsg;
            formVaildCheck = false;
        }    
    

    return { formVaildCheck: formVaildCheck, errors: errors };
}