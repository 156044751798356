import React, { useState } from 'react';
import { Button, FloatingLabel, Form } from 'react-bootstrap';
import { callCommonAction } from '../../redux/Common/CommonReducer';
import { useDispatch ,useSelector} from "react-redux";
import { validateUserPasswordFormData } from "./UpdatePasswordValidation";
import { handleApiResponse } from "../../apis/apiUtils";
import { sendRequest } from "../../apis/APIs";
import { useNavigate } from 'react-router-dom';
import appRoutes from '../../configs/AppRoutes';

const UpdatePassword = () => {
    const [userDetails, setUserDetails] = useState({
        current_password: '',
        new_password: '',
        confirm_password: '',
    });
    const [errorsInfo, setErrorsInfo] = useState({});
    const navigate = useNavigate();
    const { loading, pageData } = useSelector((state) => state.common);
    const dispatch = useDispatch();

    /** Validation implemented here */
    const checkFormIsValid = async () => {
        const res = validateUserPasswordFormData(userDetails);
        setErrorsInfo(res.errors);
        return res.formVaildCheck;
    };



    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        checkFormIsValid(e.target.name);

    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (await checkFormIsValid("")) {
            try {
                dispatch(callCommonAction({ loading: true }));
                let reqUrl = `/user/change-password`;

                const formData = {
                    current_password: userDetails.current_password,
                    new_password: userDetails.new_password,
                    new_password_confirmation: userDetails.confirm_password,
                };
                console.log(formData, "formData")
                // Call the API
                const res = await sendRequest(reqUrl, "POST", formData);
                dispatch(callCommonAction({ loading: false }));
                handleApiResponse(res);
                if (res.data.type === "success") {
                    localStorage.setItem('user', JSON.stringify(res.data.data));
                    dispatch(callCommonAction({ user: res.data.data }));
                     setUserDetails({
                        current_password: '',
                        new_password: '',
                        confirm_password: '',
                    });
                    setErrorsInfo({}); 

                } 

            } catch (error) {
                dispatch(callCommonAction({ loading: false }));
                console.error(error); // Handle the error
            }
        }
    };

    return (
        <div className='outer bg-lightBrown min-h-screen flex items-center justify-center'>
            <div className='p-6 rounded-lg shadow-md w-80'>
                <h2 className='text-xl font-semibold text-center mb-4'>Update Password</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <FloatingLabel controlId="floatingCurrentPassword" label="Enter current password">
                            <Form.Control
                                className="input-field form-control"
                                type="password"
                                placeholder="Enter current password"
                                name="current_password"
                                value={userDetails.current_password}  
                                onChange={handleChange}
                                isInvalid={!!errorsInfo.current_Password}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errorsInfo.current_Password}
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </div>
                    <div className="mb-3">
                        <FloatingLabel controlId="floatingNewPassword" label="Enter new password">
                            <Form.Control
                                className="input-field form-control"
                                type="password"
                                placeholder="Enter new password"
                                name="new_password"
                                value={userDetails.new_password} 
                                onChange={handleChange}
                                isInvalid={!!errorsInfo.new_Password}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errorsInfo.new_Password}
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </div>
                    <div className="mb-3">
                        <FloatingLabel controlId="floatingConfirmPassword" label="Enter confirm password">
                            <Form.Control
                                className="input-field form-control"
                                type="password"
                                placeholder="Enter confirm password"
                                name="confirm_password"
                                onChange={handleChange}
                                value={userDetails.confirm_password}  

                                isInvalid={!!errorsInfo.confirm_Password}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errorsInfo.confirm_Password}
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </div>
                    <div className="text-center mb-4">
                        <Button className='w-100 btn-lg btn-primary shadow' type="submit">
                            <span className="indicator-label">Update Password</span>
                        </Button>
                    </div>
                </form>
                <div className="">
                        <p onClick={() => navigate( appRoutes.profilePageRoute )} className='text-center small text-primary text-decoration-underline pointer'><i className="fa-solid fa-chevron-left small"></i> Back</p>
                    </div>
            </div>
        </div>
    );
};

export default UpdatePassword;
