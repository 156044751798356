const appRoutes = {

    homePageRoute: '/',
    registerPageRoute: '/register',
    loginPageRoute: '/login',
    thankYouPageRoute: '/thank-you',
    otpPageRoute: '/otp',
    verifyEmailPageRoute: '/verify-email',
    forgotPasswordPageRoute: '/forgot-password',

    profilePageRoute: '/user/profile',
    updatePageRoute: '/user/password/update',
    termsConditionsPageRoute: '/page/terms-and-conditions',

    /**Page url */
    selectPageTypePageRoute: '/page/type',
    createCouplePageRoute: '/page/create/couple',
    createFriendPageRoute: '/page/create/friend',
    joinPageRoute: '/page/join',
    viewPageDetailPageRoute: '/page/view',
    updateCouplePageRoute: '/page/couple/update',
    updateFriendPageRoute: '/page/friend/update',
    infoPageRoute: '/page/info',
    createEventPageRoute: '/event/create',
    updateEventPageRoute: '/page/event/update',
    viewEventPageRoute: '/page/event/view',
    bucketListPageRoute: '/page/bucket/list',
    bucketListCreatePageRoute: '/page/bucket/create',
    bucketListUpdatePageRoute: '/page/bucket/update',
    upcomingEventsPageRoute: '/page/upcoming/events',
    upcomingEventsCreatePageRoute: '/page/upcoming/event/create',
    upcomingEventsUpdatePageRoute: '/page/upcoming/event/update',
    notificationPageRoute: '/page/notification',
    calandersynchronisPageRoute: '/page/calander-synchroniz',
    eventListRoute: '/page/event/list',




}
export default appRoutes