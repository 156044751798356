// UpdatePasswordValidation.js
import STRINGS from '../../common/strings/strings';

export const validateUserPasswordFormData = (userDetails) => {
    const errors = {};
    let formValidCheck = true;

    if (!userDetails.current_password) {
        errors.current_Password = STRINGS.currentPassword;
        formValidCheck = false;
    }

    if (!userDetails.new_password) {
        errors.new_Password = STRINGS.new_password;
        formValidCheck = false;
    }

    if (userDetails.new_password !== userDetails.confirm_password) {
        errors.confirm_Password = STRINGS.confirm_password;
        formValidCheck = false;
    }
    return { errors, formVaildCheck: formValidCheck };
};
