import STRINGS from '../../common/strings/strings';
import Helper from '../../apis/Helper';

export function validatePageFormData(pageDetail, type, id = null) {
    let errorMsg = ''; let errors = {}; let formVaildCheck = true;
    if (type === 1) {
        const { cover_picture, anniversary_date } = pageDetail;
        if (cover_picture === '' && id == null) {
            errorMsg = cover_picture === '' ? STRINGS.coverPictureReq : '';
            errors.cover_picture = errorMsg;
            formVaildCheck = false;
        }
        if (anniversary_date === '') {
            errorMsg = anniversary_date === '' ? STRINGS.anniversaryDateReq : '';
            errors.anniversary_date = errorMsg;
            formVaildCheck = false;
        }
    } else {
        const { cover_picture, name, members_invited } = pageDetail;
        if (cover_picture === '' && id == null) {
            errorMsg = cover_picture === '' ? STRINGS.coverPictureReq : '';
            errors.cover_picture = errorMsg;
            formVaildCheck = false;
        }
        if (name === '') {
            errorMsg = name === '' ? STRINGS.nameReq : '';
            errors.name = errorMsg;
            formVaildCheck = false;
        }
        if (members_invited === '') {
            errorMsg = members_invited === '' ? STRINGS.membersInvitedReq : '';
            errors.members_invited = errorMsg;
            formVaildCheck = false;
        }
    }

    return { formVaildCheck: formVaildCheck, errors: errors };
}